import React, { useState, useEffect, useCallback } from "react";

//components
import DataTable from "../../../../components/Tables/DataTable";

//hooks
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import useAuth from "../../../../hooks/useAuth";

//graphql
import { GET_UNASSIGNED_EMPLOYEES } from "../../../../graphql/team";

//external components
import { CircularProgress, IconButton, Modal } from "@mui/material";

import ContainerCard from "../../../../components/Cards/ContainerCard";
import { getTable } from "./_table";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { CloseIcon } from "../../../../components/Icon";
import { CHANGE_USER_ROLE } from "../../../../graphql/role";
import { useParams } from "react-router-dom";
import useMutationAuth from "../../../../hooks/useMutationAuth";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";



const EmployeesList = ({ teamGuid = null, projectGuid = null, unassignedEmployees = true, fromProjectPermission = false, pageSize = 20, handleClick = {}, fromRoles = false, fromModal = false, display = false, setDisplay = () => {}, refetch = () => {} }) => {


  const { t } = useTranslation();
  const { storeUserGuid, storeUserTenant, userRoles } = useAuth();
  const { rID } = useParams();

  
  const { manageUsers } = userRoles;
  const [re, setRe] = useState(false);
  const [data, setData] = useState(null);
  const [defaultFilters, setDefaultFilters] = useState({});
  const [columns, setColumns] = useState([]);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pageSize);
  const [search, setSearch] = useState("");
  const [displayTable, setDisplayTable] = useState(display);


  useEffect(() => {
    if (columns.length === 0) {
      const col = getTable();

      if (col) {
        setOrderBy(col.defaultSort);
        setOrder(col.defaultSortDirection);
        setColumns(col.columns);
      }
    }
  }, [columns.length]);

  const {
    mfunction: getAllEmployees,
    loading,
    refetch: refetchData,
  } = useLazyQueryAuth(GET_UNASSIGNED_EMPLOYEES, "GetUnassignedEmployees");


  const fetchTableData = useCallback(
    (
      page = 0,
      pageSize = 0,
      order = "",
      orderBy = "",
      search = "",
      filters = null
    ) => {
      getAllEmployees(
        {
          variables: {
            model: {
              enableFilters: false,
              keys: {
                page: page + 1,
                pageSize: pageSize,
              },
              orderColumn: orderBy,
              orderDirection: order,
              keyWord: search,
              teamID: teamGuid,
              projectID: projectGuid,
              unassignedEmployees: unassignedEmployees,
              tenantID: storeUserTenant,
              userID: storeUserGuid,
              roleGuid: fromRoles || fromModal ? rID : null,
              assignNewRole: fromModal ? true : false,
            },
          },
        },
        (response) => {
          setData(response);
        }
      );
    },
    []
  );

  useEffect(() => {
    if (columns.length > 0) fetchTableData(page, rowsPerPage, order, orderBy, search, defaultFilters);
  }, [columns.length, re]);

  const rows =
    data &&
      data.GetUnassignedEmployees &&
      data.GetUnassignedEmployees &&
      data.GetUnassignedEmployees.employeesList
      ? data.GetUnassignedEmployees.employeesList.employees
      : [];
  const numberOfRecords =
    data &&
      data.GetUnassignedEmployees &&
      data.GetUnassignedEmployees &&
      data.GetUnassignedEmployees.employeesList.totalNumberOfRecords
      ? data.GetUnassignedEmployees.employeesList.totalNumberOfRecords
      : 0;

  const customButtons = [
    {
      label: "employeeForm.addButton",
      handleClick: () => handleClick(selected),
      condition: selected && selected.length > 0 && manageUsers === "M",
    }
  ];

  const pagination = {
    rows,
    columns,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    selected,
    defaultFilters,
    setDefaultFilters,
    setSelected,
    search,
    setSearch,
    setPage,
    setRowsPerPage,
    numberRecords: numberOfRecords,
    page,
    rowsPerPage,
    fetchTableData,
    loading: loading,
    searchText: "employee.list.search.label",
    readOnly: manageUsers !== "M",
    removeFilters: true,
    hideEditColumns: true,
    heightClassName: fromProjectPermission ? "table-pop-up-height" : "",
  };



  return (
    <div>
      {fromRoles && displayTable ? (

        <Modal
          open={displayTable}
          onClose={() => {setDisplay(false); setDisplayTable(false);  refetchData();}}
          sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        >
          <ContainerCard
            sx={{ padding: "10px 20px 20px", width: "70%", height: "fitContent" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "1rem",
                width: "100%",
              }}
            >
              <IconButton
                edge="end"
                color="inherit"
                onClick={() => {setDisplay(false); setDisplayTable(false); refetchData()}}
                aria-label="close"
              >
                <CloseIcon color={"#000000"} width={'20'} stroke={'1'} />
              </IconButton>
            </div>
            <EmployeesList
              display={displayTable}
              initialVal={[]}
              setDisplay={setDisplayTable}
              refetch={refetchData}
              fromModal
            /></ContainerCard>
        </Modal>

      ) : null}
      {loading && columns.length === 0 ? (
        <ContainerCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 20px",
          }}
        >
          <CircularProgress />
        </ContainerCard>
      ) : (
        <DataTable {...pagination} identifier={"employeeGuid"} minWidth={500} hasCheckbox={fromRoles? false : true } heightClassName={fromRoles ? "tableHeightProjectsEmployees" : ""} />
      )}
    </div>
  );
};

export default EmployeesList;
