import { Button,  } from "@mui/material";
import { useTranslation } from "react-i18next";

import useQueryAuth from "../../../../../hooks/useQueryAuth";
import useMutationAuth from "../../../../../hooks/useMutationAuth";
import { LOGOUT_USER } from "../../../../../graphql/user";
import {
  CREATE_TASK_COMMENT,
  DELETE_TASK_COMMENT,
  EDIT_TASK_COMMENT,
  GET_ALL_TASK_COMMENTS,
} from "../../../../../graphql/taskComment";
import useAuth from "../../../../../hooks/useAuth";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useRef, useState } from "react";
import ConfirmationModal from "../../../../../components/Modal/ConfirmationModal";
import CustomButton from "../../../../../components/CustomButton";
import apiCall from "../../../../../hooks/useRest";
import ReactQuill, { Quill } from "react-quill-new";
import "react-quill-new/dist/quill.snow.css";
import CommentCard from "../../../../../components/Cards/CommentCard";
import hasEmptyTags from "../../../../../utils/hasEmptyTags";

import { Mention, MentionBlot } from "quill-mention";
import "quill-mention/autoregister";
import { saveToken } from "../../../../../app/reducers/tokenSlice";
import { useDispatch } from "react-redux";

var Parchment = Quill.import("parchment");
var Image = Quill.import("formats/image");

Image.className = "custom-image-size";
Quill.register(Image, true);

Quill.register({ "blots/mention": MentionBlot, "modules/mention": Mention });

const backendURL = process.env.REACT_APP_REST_URL;

function Comment({
  employees_list,
  reload,
  taskGuid,
  showModal,
  wait,
  fullscreenComment,
  setFullscreenComment,
  setTaskComment,
  refreshCommentSection,
  setRefreshCommentSection,

}) {
  const { t } = useTranslation();

  const [newComment, setNewComment] = useState("");

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const [taskCommentState, setTaskCommentState] = useState(null);
  const [comments, setComments] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const quillRef_comment = useRef();
  const imageUrl = useRef("");

  const { mfunction: logsOutUser } = useMutationAuth(LOGOUT_USER, "LogoutUser");

  const { cfunction: deleteTaskComment } = useMutationAuth(
    DELETE_TASK_COMMENT,
    "DeleteTaskComment"
  );

  const { cfunction: updateTaskCommentData } = useMutationAuth(
    EDIT_TASK_COMMENT,
    "UpdateTaskComment"
  );

  function UpdateTaskCommentData(comment, taskCommentGuid) {
    setLoading(true);
  
    let updatedComment = comment.replace(/<p>/g, '<p style="margin:0px">');
    
    // Check for empty tags and reset the comment if needed
    if (hasEmptyTags(updatedComment)) updatedComment = "";
  
    // a temporary DOM element to hold the updated comment HTML
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = updatedComment;
  
    // Select only the mentions from this temporary DOM structure
    const mentionSpans = tempDiv.querySelectorAll(".mention");
    const mentionsArr = [];
    mentionSpans.forEach((mention) => {
      mentionsArr.push(mention.dataset.id);
    });
  
    // Proceed with updating the task comment
    updateTaskCommentData(
      {
        variables: {
          model: {
            userID: storeUserGuid,
            description: updatedComment,
            taskCommentGuid: taskCommentGuid,
            mentions: mentionsArr,  
          },
        },
      },
      (response) => {
        if (
          response["UpdateTaskComment"].errorCode !== null &&
          response["UpdateTaskComment"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["UpdateTaskComment"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000,
          });
          setLoading(false);
        } else {
          var taskComment = response.UpdateTaskComment.taskComment;
          const updatedComments = comments.map((comment) => {
            if (comment.taskCommentGuid === taskComment.taskCommentGuid) {
              return taskComment;
            }
            return comment;
          });
  
          // Sort the updated comments list by updatedAt or createdAt
          updatedComments.sort((a, b) => {
            const aUpdatedAt = a.updatedAt
              ? new Date(a.updatedAt).getTime()
              : new Date(a.createdAt).getTime();
            const bUpdatedAt = b.updatedAt
              ? new Date(b.updatedAt).getTime()
              : new Date(b.createdAt).getTime();
  
            return bUpdatedAt - aUpdatedAt;
          });
          setComments(updatedComments);
          enqueueSnackbar(t("projectForm.createdSuccessfully"), {
            variant: "success",
          });
          handleCancel();
          setEditMode(false);
        }
        setLoading(false);
      },
      (error) => {
        setLoading(false);
      }
    );
  }
  

  const { cfunction: createTaskCommentData } = useMutationAuth(
    CREATE_TASK_COMMENT,
    "CreateTaskComment"
  );
  function CreateTaskCommentData() {
    if (!newComment || newComment.trim() === "") {
      // If the comment is empty or contains only whitespace, return early
      return;
    }
  
    setLoading(true);
  
    const quill = document.querySelector(".comment-quill");
    const mentionSpans = quill.querySelectorAll(".mention");
    const mentionsArr = [];
    mentionSpans.forEach((mention) => {
      mentionsArr.push(mention.dataset.id);
    });
  
    let updatedComment = newComment.replace(/<p>/g, '<p style="margin:0px">');
    if (hasEmptyTags(updatedComment)) updatedComment = "";
  
    createTaskCommentData(
      {
        variables: {
          model: {
            taskId: taskGuid,
            userID: storeUserGuid,
            description: updatedComment,
            mentions: mentionsArr,
          },
        },
      },
      (response) => {
        if (
          response["CreateTaskComment"].errorCode !== null &&
          response["CreateTaskComment"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["CreateTaskComment"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000,
          });
          setLoading(false);
        } else {
          var taskComment = response.CreateTaskComment.taskComment;
          const commentsList = [...comments, taskComment];
          commentsList.sort((a, b) => {
            const aUpdatedAt = a.updatedAt
              ? new Date(a.updatedAt).getTime()
              : new Date(a.createdAt).getTime();
            const bUpdatedAt = b.updatedAt
              ? new Date(b.updatedAt).getTime()
              : new Date(b.createdAt).getTime();
            return bUpdatedAt - aUpdatedAt;
          });
          setComments(commentsList);
          setNewComment("");
          enqueueSnackbar(t("projectForm.createdSuccessfully"), {
            variant: "success",
          });
          handleCancel();
          setEditMode(false);
        }
        setLoading(false);
      },
      (error) => {
        setLoading(false);
      }
    );
  }
  

  const {
    logout,
    jwtToken,
    storeUserTenant,
    storeUserGuid,
    storeUserTenantName,
  } = useAuth();

  const {
    data: commentsData,
    loading: commentsLoading,
    tokenChange: commentsWait,
    refetch: refetchComments,
  } = useQueryAuth(GET_ALL_TASK_COMMENTS, "GetAllTaskComments", {
    variables: { id: taskGuid },
    skip: !taskGuid || wait,
    isWait: taskGuid,
  });

  useEffect(() => {
    if(!wait && refreshCommentSection) refetchComments();
    if(commentsData) setComments(commentsData?.GetAllTaskComments?.taskCommentsList || []);
    setNewComment("");
    handleCancel();
    setEditMode(false);
    setRefreshCommentSection(false);
  }, [commentsData, refreshCommentSection]);

  useEffect(() => {
    const allImgs = document.querySelectorAll(".custom-image-size");

    const handleImageClick = (event) => {
      event.preventDefault();
      event.stopImmediatePropagation();
      window.open(event.target.src, "_blank");
    };

    const attachListeners = (imgs) => {
      imgs.forEach((img) => {
        img.addEventListener("click", handleImageClick);
      });
    };

    if (allImgs && allImgs.length > 0) {
      attachListeners(allImgs);
    }

    // Observe changes in the DOM to handle dynamically added images
    const observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === "childList") {
          const newImgs =
            mutation.target.querySelectorAll(".custom-image-size");
          attachListeners(newImgs);
        }
      });
    });

    observer.observe(document.body, { childList: true, subtree: true });

    // Cleanup function to remove event listeners on component unmount
    return () => {
      if (allImgs && allImgs.length > 0) {
        allImgs.forEach((img) => {
          img.removeEventListener("click", handleImageClick);
        });
      }

      // Disconnect the observer when the component is unmounted
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    const quill = document.querySelector(".comment-quill");
    if (quill) {
      const allContainers = quill.querySelectorAll(".ql-container");
      const allToolbars = quill.querySelectorAll(".ql-toolbar");

      allToolbars.forEach((toolbar) => {
        if (toolbar && toolbar.style) {
          toolbar.style.display = "none";
          toolbar.style.borderTopLeftRadius = "5px";
          toolbar.style.borderTopRightRadius = "5px";
        }
      });

      allContainers.forEach((container) => {
        if (container && container.style) {
          container.style.borderTop = "1px solid #cccccc";
          container.style.borderRadius = "5px";
        }
      });

      const actionButtons = quill.querySelector(".comments-action-buttons");
      if (actionButtons && actionButtons.style) {
        actionButtons.style.display = "none";
      }

      const regularToolbar = quill.querySelector(".ql-toolbar");
      const regularContainer = quill.querySelector(".ql-container");

      if (regularToolbar) {
        regularToolbar.addEventListener("click", (event) => {
          event.stopPropagation();
          event.preventDefault();
        });
      }

      quill.addEventListener("focusin", () => {
        // console.log("focused in");
        if (regularToolbar && regularToolbar.style) {
          regularToolbar.style.display = "block";
        }
        if (regularContainer && regularContainer.style) {
          regularContainer.style.borderTopLeftRadius = "0px";
          regularContainer.style.borderTopRightRadius = "0px";
        }
        if (actionButtons && actionButtons.style) {
          actionButtons.style.display = "flex";
          actionButtons.style.justifyContent = "flex-end";
        }
      });

      quill.addEventListener("focusout", () => {
        // console.log("focused out");
        //   setTimeout(() => {
        // if (regularToolbar && regularToolbar.style) {
        //   regularToolbar.style.display = "none";
        // }
        // if (regularContainer && regularContainer.style) {
        //   regularContainer.style.borderTopLeftRadius = "5px";
        //   regularContainer.style.borderTopRightRadius = "5px";
        // }
        //   }, 1000);
      });
    }
  }, []);

  const handleCancel = () => {
    const commentQuill = document.querySelector(".comment-quill");
    if (commentQuill) {
      const allContainers = commentQuill.querySelectorAll(".ql-container");
      const allToolbars = commentQuill.querySelectorAll(".ql-toolbar");

      allToolbars.forEach((toolbar) => {
        if (toolbar && toolbar.style) {
          toolbar.style.display = "none";
        }
      });
      allContainers.forEach((container) => {
        if (container && container.style) {
          container.style.borderTopLeftRadius = "5px";
          container.style.borderTopRightRadius = "5px";
        }
        if (container) container.blur();
      });

      const actionButtons = commentQuill.querySelector(
        ".comments-action-buttons"
      );
      if (actionButtons && actionButtons.style) {
        actionButtons.style.display = "none";
      }
      setNewComment("");
    }
  };

  const handleDelete = (taskCommentGuid) => {
    setTaskCommentState(taskCommentGuid);
    setOpenDialog(true);
  };

  const handleConfirmDelete = () => {
    setLoading(true);
    deleteTaskComment(
      {
        variables: {
          model: {
            // taskGuid: taskGuid.toString(),
            // tenantName: storeUserTenantName,
            userID: storeUserGuid,
            taskCommentGuid: taskCommentState,
          },
        },
      },
      (response) => {
        if (
          response["DeleteTaskComment"].errorCode !== null &&
          response["DeleteTaskComment"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["DeleteTaskComment"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000,
          });
        } else {
          enqueueSnackbar(t("comment.deletedSuccessfully"), {
            variant: "success",
          });
          // reload('3');
          setComments(
            comments.filter((x) => x.taskCommentGuid !== taskCommentState)
          );
        }
        handleClose();
        setLoading(false);
      }
    );
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  function extractTypeFromDataURL(dataURL) {
    const base64String = dataURL.split(",")[0].split(";")[0];
    const type = base64String.substring(5);
    return type;
  }

  function base64ToBlob(base64Data, contentType = "image/png") {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  function base64ToFile(base64Data, filename, contentType) {
    const blob = base64ToBlob(base64Data, contentType);
    return new File([blob], filename, { type: contentType });
  }

  async function replaceAndUploadImage(htmlText, filenameTemplate) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlText, "text/html");

    const images = doc.querySelectorAll("img");
    let replacements = [];

    for (let img of images) {
      if (img.src.startsWith("data:image/")) {
        const base64String = img.src.split(",")[1]; // Extract base64 without the prefix
        const contentType = extractTypeFromDataURL(img.src);
        const ext = contentType.split("/")[1];
        const filename = `${generateRandomName()}.${ext}`;

        const file = base64ToFile(base64String, filename, contentType);

        // Prepare form data for the upload
        let formData = new FormData();
        formData.append("image", file, file.name);
        formData.append("tenantID", storeUserTenant);
        if (taskGuid) formData.append("itemID", taskGuid);

        // Upload the image and replace the src with the new URL
        const newUrl = await uploadImage(formData, false);
        img.src = newUrl; // Update the src in the DOM

        replacements.push(newUrl);
      }
    }
    return doc.body.innerHTML;
  }

  function generateRandomName(length = 12) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  }

  useEffect(() => {
    const quillCommentContainer = document.querySelector(".comment-quill");
    if (quillCommentContainer) {
      const editor = quillCommentContainer.querySelector(".ql-editor");
      if (editor) {
        editor?.addEventListener("paste", async (event) => {
          let data = event.clipboardData || window.clipboardData;
          let html = event.explicitOriginalTarget;
          if (data.files?.length > 0) {
            event.preventDefault();
            var file = data.files[0];
            var imageType = /image.*/;

            if (file.type.match(imageType)) {
              var formData = new FormData();
              formData.append("image", file, file.name);
              formData.append("tenantID", storeUserTenant);
              if (taskGuid) formData.append("itemID", taskGuid);
            }
            await uploadImage(formData, true);
          } else if (html?.innerHTML) {
            const innerHTML = await replaceAndUploadImage(
              html?.innerHTML,
              generateRandomName()
            );
            setNewComment(innerHTML);
          }
        });
      }
    }
  }, []);

  const updateToken = (updatedToken) => {
    if (updatedToken) dispatch(saveToken(updatedToken));
  };

  const removeImg = () => {
    const images = document.querySelectorAll("img");
    // Loop through each image element
    images.forEach((img) => {
      // Check if the src attribute contains "data:image/png;base64"
      if (img.src.startsWith("data:image/")) {
        // Remove the image element from the DOM
        img.remove();
      }
    });
  };

  async function uploadImage(formData, newImage) {
    const quill = quillRef_comment.current;
    let range =
      quill.getEditorSelection() || quill?.editor?.selection?.savedRange;

    const res = await apiCall(
      backendURL + `/Task/UploadTaskImage`,
      "POST",
      formData,
      logsOutUser,
      logout,
      updateToken,
      enqueueSnackbar,
      t
    );

    if (res) {
      imageUrl.current = res.data.url;
      if (newImage === true) {
        removeImg();
        quill
          .getEditor()
          .insertEmbed(range.index, "image", `${imageUrl.current}`);
      }
    } else {
      removeImg();
    }

    return imageUrl.current;
  }

  function imageHandler_comment() {
    const input = document.createElement("input");

    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = () => {
      // var file = input && input.files ? input.files[0] : null;
      var file = input.files[0];
      var imageType = /image.*/;

      if (file.type.match(imageType)) {
        var formData = new FormData();
        formData.append("image", file, file.name);
        formData.append("tenantID", storeUserTenant);
        if (taskGuid) formData.append("itemID", taskGuid);
      }
      uploadImage(formData, true);
    };
  }

  function handleArrowKeyNavigation(event, listElement, selectedItemIndex) {
    const selectedItem = listElement.children[selectedItemIndex];
    const listHeight = listElement.clientHeight;
    const itemTop = selectedItem.offsetTop;
    const itemHeight = selectedItem.clientHeight;
    const scrollTop = listElement.scrollTop;

    // Check if the selected item is above the visible area
    if (itemTop < scrollTop) {
      listElement.scrollTop = itemTop;
    }

    // Check if the selected item is below the visible area
    if (itemTop + itemHeight > scrollTop + listHeight) {
      listElement.scrollTop = itemTop + itemHeight - listHeight;
    }
  }

  // useEffect(() => {
  //   const mentionListItem = document.querySelectorAll('.ql-mention-list');
  //   if(mentionListItem) {
  //     document.addEventListener('keydown', (event) => {
  //       if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
  //           const listElement = document.querySelector('.mention-list'); // Your mention list container
  //           const selectedItemIndex = /* Your logic to get the selected item index */;
  //           handleArrowKeyNavigation(event, listElement, selectedItemIndex);
  //       }
  //   });
  //   }
  // }, [])

  const modules_comment = useMemo(
    () => ({
      toolbar: {
        handlers: {
          image: imageHandler_comment,
        },
        container: [
          [{ size: ["small", false, "large", "huge"] }],
          ["bold", "italic", "underline"],
          [{ color: [] }, { background: [] }],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ indent: "-1" }, { indent: "+1" }],
          [({ direction: "" }, { direction: "rtl" })],
          [
            { align: "" },
            { align: "center" },
            { align: "right" },
            { align: "justify" },
          ],
          // ["hight"],
          ["clean"],
          ["image"],
          ["link"],
        ],

        clipboard: {
          matchVisual: false,
        },
        imageResize: {},
      },
      mention: {
        allowedChars: /^[A-Za-z\s]*$/,
        mentionDenotationChars: ["@"],
        positioningStrategy: "fixed",
        renderLoading: () => {
          return "Loading...";
        },
        // onOpen: function() {
        //   if(employees_list?.length > 0){
        //     const mentionListItem = document.querySelector('.ql-mention-list-item');
        //     console.log(document.querySelectorAll('.ql-mention-list-item'));
        //     mentionListItem?.focus();
        //   }
        // },
        source: function (searchTerm, renderList) {
          const mappedEmployees = employees_list?.map((employee) => {
            return {
              id: employee.employeeGuid,
              value: employee.firstName + " " + employee.lastName,
            };
          });
          const matchedPeople = mappedEmployees.filter((person) =>
            person.value.toLowerCase().includes(searchTerm.toLowerCase())
          );

          if (searchTerm.length === 0) {
            renderList(matchedPeople);
          } else {
            const matches = [];
            for (let i = 0; i < matchedPeople.length; i++) {
              if (
                ~matchedPeople[i].value
                  .toLowerCase()
                  .indexOf(searchTerm.toLowerCase())
              ) {
                matches.push(matchedPeople[i]);
              }
            }
            renderList(matches, searchTerm);
          }
        },
        // renderItem: (item, searchTerm) => {
        //   return `<div>${item.value}</div>`;
        // },
        onSelect: (item, insertItem) => {
          // console.log("item", item);
          insertItem(item);
        },
      },
    }),
    []
  );

  const formats = [
    "size",
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "indent",
    "align",
    "background",
    "image",
    "color",
    "background-color",
    "link",
    "mention",
  ];

  const customModalButtons = () => (
    <>
      <CustomButton
        onClick={handleConfirmDelete}
        label={t("dialog.yes")}
        disabled={loading}
      />
      <div>
        <CustomButton
          onClick={handleClose}
          label={t("dialog.cancel")}
          disabled={loading}
        />
      </div>
    </>
  );

  const handleQuillChange = (value) => {
    setNewComment(value);
    setTaskComment(value);
  };

  return (
    <div style={{ marginTop: "10px" }}>
      <ConfirmationModal
        contentText={t("dialog.user.delete.comment.confirmation")}
        title={t("dialog.warning")}
        icon={"warning"}
        openDialog={openDialog}
        handleClose={handleClose}
        customButtons={customModalButtons}
        t={t}
      />
      <>
        { (
          <div
            style={{
              width: "100%",
              marginBottom: "10px",
            }}
          >
            <ReactQuill
              ref={quillRef_comment}
              className="comment-quill"
              bounds={"#root"}
              name="body"
              value={newComment}
              onChange={handleQuillChange}
              modules={modules_comment}
              formats={formats}
              placeholder={t("taskForm.comment.placeholder")}
            />
            <div className="comments-action-buttons">
              <Button
                variant="text"
                disabled={loading}
                onClick={handleCancel}
                size="small"
              >
                {t("dialog.cancel")}
              </Button>
              <Button
                variant="text"
                disabled={newComment === "" || loading}
                onClick={CreateTaskCommentData}
                size="small"
              >
                {t("dialog.save")}
              </Button>
            </div>
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            columnGap: "20px",
            flexWrap: "wrap",
            // maxHeight: "calc(100vh - 450px)",
            // overflowY: "scroll",
            rowGap: "20px",
            height: fullscreenComment ? "calc(100vh - 300px)" : "initial",
          }}
        >
          {comments.map((comment, index) => (
            <CommentCard
              key={index}
              comment={comment}
              handleDelete={handleDelete}
              storeUserGuid={storeUserGuid}
              modules={modules_comment}
              formats={formats}
              UpdateTaskCommentData={UpdateTaskCommentData}
              handleQuillChange={handleQuillChange}
              loading={loading}
              editMode={editMode}
              setEditMode={setEditMode}
              fullscreenComment={fullscreenComment}
              setFullscreenComment={setFullscreenComment}
              t={t}
            />
          ))}
        </div>
      </>
    </div>
  );
}

export default Comment;
