import React, { useState, useEffect, useCallback } from "react";

//routes
import { useParams } from "react-router-dom";

//components
import DataTable from "../../../../components/Tables/DataTable";

//hooks
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useAuth from "../../../../hooks/useAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";

//graphql
import {
  DELETE_PROJECT_PERMISSIONS_LIST,
  GET_ALL_PROJECT_PERMISSIONS,
  ASSIGN_RESOURCES_TO_PROJECT,
} from "../../../../graphql/projectPermission";

import { getDataTableData } from "./_table";

import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";

//external components
import { CircularProgress } from "@mui/material";

import ContainerCard from "../../../../components/Cards/ContainerCard";

import EmployeesList from "../../Teams/Employees-list";

import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import PopUpList from "../../../../components/Modal/PopUpList";

const ProjectPermission = ({ myCompany = false, fromTenant = false, projectGuid,  setDisplay = () => {}, fromResource=false }) => {
  const { pID } = useParams();

  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const { storeUserGuid, userRoles } = useAuth();

  const { manageTimeEntry, manageUsers } = userRoles;

  const [re, setRe] = useState(false);
  const [data, setData] = useState(null);
  const [defaultFilters, setDefaultFilters] = useState({});
  const [columns, setColumns] = useState([]);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    const col = getDataTableData(t);
    if (col) {
      setColumns(col.columns);
      setOrderBy(col.defaultSort);
      setOrder(col.defaultSortDirection);
    }
  }, [columns?.length]);

  const { mfunction: getAllProjectPermissions, loading } = useLazyQueryAuth(
    GET_ALL_PROJECT_PERMISSIONS,
    "GetProjectPermissions"
  );

  const fetchTableData = useCallback(
    (page = 0, pageSize = 0, order = "", orderBy = "", search = "") => {
      getAllProjectPermissions(
        {
          variables: {
            model: {
              enableFilters: true,
              keys: {
                page: page + 1,
                pageSize: pageSize,
              },
              orderColumn: orderBy,
              orderDirection: order,
              keyword: search,
              userID: storeUserGuid,
              projectID: pID || projectGuid,
            },
          },
        },
        (response) => {
          setData(response);
        }
      );
    },
    []
  );

  useEffect(() => {
    if (columns.length > 0)
      fetchTableData(page, rowsPerPage, order, orderBy, search, defaultFilters);
  }, [columns.length, fetchTableData, re]);

  const rows =
    data &&
    data.GetProjectPermissions &&
    data.GetProjectPermissions.projectPermissionsList &&
    data.GetProjectPermissions.projectPermissionsList.projectPermissions
      ? data.GetProjectPermissions.projectPermissionsList.projectPermissions
      : [];
  const numberOfRecords =
    data &&
    data.GetProjectPermissions &&
    data.GetProjectPermissions.projectPermissionsList &&
    data.GetProjectPermissions.projectPermissionsList.totalNumberOfRecords
      ? data.GetProjectPermissions.projectPermissionsList.totalNumberOfRecords
      : 0;
  const { mfunction: deleteRecord, loading: deleteLoading } = useMutationAuth(
    DELETE_PROJECT_PERMISSIONS_LIST,
    "DeleteProjectPermissionsList"
  );

  const handleDelete = () => {
    deleteRecord(
      {
        variables: {
          model: {
            projectPermissionGuids: selected,
          },
        },
      },
      (response) => {
        enqueueSnackbar(t("projects.list.delete.success"), {
          variant: "success",
        });
        setRe((v) => !v);
        setSelected([]);
        setPage(0);
      }
    );
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const {
    mfunction: assignResourcesToProject,
    loading: assignResourcesToProjectLoading,
  } = useMutationAuth(ASSIGN_RESOURCES_TO_PROJECT, "AssignResourcesToProject");

  const handleAddResource = (resourcesGUIDs) => {
    assignResourcesToProject(
      {
        variables: {
          model: {
            userID: storeUserGuid,
            resourcesIDs: resourcesGUIDs,
            projectGuid: pID,
          },
        },
      },
      (data) => {
        enqueueSnackbar(t("assignResourcesToProject.success"), {
          variant: "success",
        });
        setRe((val) => !val);
        handleClose();
        setDisplay(false)
      }
    );
  };

  const customButtons = [
    {
      label: "projectPermission.add.new.resource",
      handleClick: () => setOpenDialog(true),
      condition:
        (!selected || selected.length === 0) &&
        manageTimeEntry === "M" &&
        manageUsers === "M" && !fromResource,
      icon: true,
      iconButton: (
        <AddCircleRoundedIcon
          sx={{
            color: "var(--color--light-logo-blue)",
            fontSize: "28px !important",
          }}
        />
      ),
    },
  ];

  const pagination = {
    rows,
    columns,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    selected,
    setSelected,
    defaultFilters,
    setDefaultFilters,
    search,
    setSearch,
    setPage,
    setRowsPerPage,
    numberRecords: numberOfRecords,
    page,
    rowsPerPage,
    fetchTableData,
    loading: loading,
    searchText: "projectPermission.list.search.label",
    deleteText: "projectPermission.list.delete.label",
    handleDelete: handleDelete,
    deleteLoading,
    readOnly: manageTimeEntry !== "M",
    removeFilters: true,
    hideEditColumns: true,
    customButtons,
  };

  return (
    <div>
      <PopUpList
        title={t("projectPermission.add.new.resource")}
        content={
          <EmployeesList
            projectGuid={pID}
            unassignedEmployees={false}
            pageSize={20}
            handleClick={handleAddResource}
            fromProjectPermission
          />
        }
        openDialog={openDialog}
        handleClose={handleClose}
        t={t}
      />
      {loading && columns.length === 0 ? (
        <ContainerCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <CircularProgress />
        </ContainerCard>
      ) : (
        columns &&
        columns.length > 0 && (
          <DataTable
            {...pagination}
            identifier={"projectPermissionGuid"}
            hasCheckbox
          />
        )
      )}
    </div>
  );
};

export default ProjectPermission;
