import React from "react";
import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";

import Tooltip from "../../../../../components/Tooltip";

//components
import {
  Grid,
  Box,
  Button,
  Popper,
  IconButton,
  Menu,
  MenuItem,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import {
  Title,
  MiniSubtitle,
} from "../../../../../components/Titles/FormTitles";
import AutoComplete from "../../../../../components/AutoComplete";

//translation
import { useTranslation } from "react-i18next";

//hooks
import useQueryAuth from "../../../../../hooks/useQueryAuth";
import useAuth from "../../../../../hooks/useAuth";
import colors from "../../../../../assets/theme/light/colors";
import ReactQuill, { Quill } from "react-quill-new";
import "react-quill-new/dist/quill.snow.css";
//graphql
import { GET_ALL_AREAS } from "../../../../../graphql/areas";
import { GET_ALL_TICKET_STATUSES } from "../../../../../graphql/ticketStatus";
import { day_month_year } from "../../../../../utils/timezones";
import "./style.css";

import AreasList from "../../../Areas";
import FormModal from "../../../../../components/Modal/FormModal";
import Mnemonics from "../../../../../Mnemonics.json";
import componentDisplay from "../../../../../componentDisplay";
import axios from "axios";
import { LOGOUT_USER } from "../../../../../graphql/user";
import { useSnackbar } from "notistack";
import useMutationAuth from "../../../../../hooks/useMutationAuth";
import { parseJwt } from "../../../../../utils/parseJwt";
import TextField from "../../../../../components/TextFields/TextField";
import { AlertIcon } from "../../../../../components/Icon";
import apiCall from "../../../../../hooks/useRest";
import { saveToken } from "../../../../../app/reducers/tokenSlice";
import { useDispatch } from "react-redux";

import { v4 as uuidv4 } from "uuid";

var Image = Quill.import("formats/image");
Image.className = "custom-image-size";
Quill.register(Image, true);

const backendURL = process.env.REACT_APP_REST_URL;

const Main = ({
  formData,
  phaseId,
  ticketID,
  modifyFormFlag,
  handleChangeFlag,
  mediaList,
  setMediaList,
  updateTicketQuick,
  handleSubmit,
  openModal,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { pID, tID } = useParams();
  const [anchorElStatus, setAnchorElStatus] = useState(null);
  const [openAreaModal, setOpenAreaModal] = useState(false);
  const [fetchAreas, setFetchAreas] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);

  const {
    storeUserGuid,
    storeUserTenant,
    userRoles,
    isEmployee,
    jwtToken,
    logout,
  } = useAuth();

  const dispatch = useDispatch();

  const { manageTimeEntry, manageSystemConfigurations } = userRoles;

  const userTimezone = Intl.DateTimeFormat()
    .resolvedOptions()
    .timeZone.split("/")[0];

  const quillRef = React.useRef();
  const imageUrl = useRef("");

  const {
    values,
    touched,
    formField,
    errors,
    setFieldValue,
    setTouched,
    createMode,
    readOnly,
    wait,
    fromTenant,
    modal,
  } = formData;

  const {
    title,
    description,
    area,
    status,
    EstimatedStartDate,
    EstimatedEndDate,
    EstimatedTime,
    externalReference,
    ActualStartDate,
    ActualEndDate,
    ActualTime,
  } = formField;

  const {
    title: titleV,
    description: descriptionV,
    area: areaV,
    synced: syncedV,
    status: statusV,
    externalReference: externalReferenceV,
    EstimatedStartDate: EstimatedStartDateV,
    EstimatedEndDate: EstimatedEndDateV,
    EstimatedTime: EstimatedTimeV,
    ActualStartDate: ActualStartDateV,
    ActualEndDate: ActualEndDateV,
    ActualTime: ActualTimeV,
  } = values;

  const { mfunction: logsOutUser } = useMutationAuth(LOGOUT_USER, "LogoutUser");

  const updateToken = (updatedToken) => {
    if (updatedToken) dispatch(saveToken(updatedToken));
  };

  const removeImg = () => {
    const images = document.querySelectorAll("img");
    // Loop through each image element
    images.forEach((img) => {
      // Check if the src attribute contains "data:image/png;base64"
      if (img.src.startsWith("data:image/")) {
        // Remove the image element from the DOM
        img.remove();
      }
    });
  };

  async function uploadImage(formData, newImage) {
    setLoadingImage(true);
    const quill = quillRef.current;
    let range =
      quill.getEditorSelection() || quill?.editor?.selection?.savedRange;

    const res = await apiCall(
      backendURL + `/Ticket/UploadTicketImage`,
      "POST",
      formData,
      logsOutUser,
      logout,
      updateToken,
      enqueueSnackbar,
      t
    );

    if (res) {
      let tempMediaList = mediaList;
      tempMediaList.push(res.data.fileName);
      setMediaList(tempMediaList);
      imageUrl.current = res.data.url;
      if (newImage === true) {
        removeImg();
        quill
          .getEditor()
          .insertEmbed(range.index, "image", `${imageUrl.current}`);
      }
      setLoadingImage(false);
    } else {
      setLoadingImage(false);
      removeImg();
    }

    return imageUrl.current;
  }

  function imageHandler() {
    const input = document.createElement("input");

    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = () => {
      // var file = input && input.files ? input.files[0] : null;
      var file = input.files[0];
      var imageType = /image.*/;

      if (file.type.match(imageType)) {
        var formDataObj = new FormData();
        formDataObj.append("image", file, file.name);
        formDataObj.append("tenantID", storeUserTenant);
        if (ticketID) formDataObj.append("itemID", ticketID);
      }
      uploadImage(formDataObj);
    };
  }

  const modules = React.useMemo(
    () => ({
      toolbar: {
        container: [
          [{ size: ["small", false, "large", "huge"] }],
          ["bold", "italic", "underline"],
          [{ color: [] }, { background: [] }],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ indent: "-1" }, { indent: "+1" }],
          [({ direction: "" }, { direction: "rtl" })],

          [
            { align: "" },
            { align: "center" },
            { align: "right" },
            { align: "justify" },
          ],
          ["link"],
          ["image"],
          ["clean"],
        ],

        clipboard: {
          matchVisual: false,
        },
        imageResize: {
          parchment: Quill.import("parchment"),
          modules: ["Resize", "DisplaySize"],
        },
        handlers: {
          image: imageHandler,
        },
      },
    }),
    []
  );

  useEffect(() => {
    const allImgs = document.querySelectorAll(".custom-image-size");

    const handleClick = (event) => {
      const img = event.target;
      const imageWindow = window.open("", "_blank");
      imageWindow?.document.write(`
        <html>
          <head></head>
          <body>
            <img src="${img.src}" alt="image">
          </body>
        </html>
      `);
    };

    if (allImgs && allImgs.length > 0) {
      allImgs.forEach((img) => {
        img.addEventListener("click", handleClick);
      });
    }

    // Cleanup event listeners on component unmount
    return () => {
      if (allImgs && allImgs.length > 0) {
        allImgs.forEach((img) => {
          img.removeEventListener("click", handleClick);
        });
      }
    };
  }, []);

  function extractTypeFromDataURL(dataURL) {
    const base64String = dataURL.split(",")[0].split(";")[0];
    const type = base64String.substring(5);
    return type;
  }

  function base64ToBlob(base64Data, contentType = "image/png") {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  function base64ToFile(base64Data, filename, contentType) {
    const blob = base64ToBlob(base64Data, contentType);
    return new File([blob], filename, { type: contentType });
  }

  async function replaceAndUploadImage(htmlText, filenameTemplate) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlText, "text/html");

    const images = doc.querySelectorAll("img");
    let replacements = [];

    for (let img of images) {
      if (img.src.startsWith("data:image/")) {
        const base64String = img.src.split(",")[1]; // Extract base64 without the prefix
        const contentType = extractTypeFromDataURL(img.src);
        const ext = contentType.split("/")[1];
        const filename = `${generateRandomName()}.${ext}`;

        const file = base64ToFile(base64String, filename, contentType);

        // Prepare form data for the upload
        let formData = new FormData();
        formData.append("image", file, file.name);
        formData.append("tenantID", storeUserTenant);
        if (ticketID) formData.append("itemID", ticketID);

        // Upload the image and replace the src with the new URL
        const newUrl = await uploadImage(formData, false);
        img.src = newUrl; // Update the src in the DOM

        replacements.push(newUrl);
      }
    }
    return doc.body.innerHTML;
  }

  function generateRandomName(length = 12) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  }

  useEffect(() => {
    const editor = document.querySelector(".ql-editor");

    if (editor) {
      editor?.addEventListener("paste", async (event) => {
        let data = event.clipboardData || window.clipboardData;
        let html = event.explicitOriginalTarget;
        if (data.files?.length > 0) {
          event.preventDefault();
          var file = data.files[0];
          var imageType = /image.*/;

          if (file.type.match(imageType)) {
            var formData = new FormData();
            formData.append("image", file, file.name);
            formData.append("tenantID", storeUserTenant);
            if (ticketID) formData.append("itemID", ticketID);
          }
          await uploadImage(formData, true);
        } else if (html?.innerHTML) {
          const innerHTML = await replaceAndUploadImage(
            html?.innerHTML,
            generateRandomName()
          );
          setFieldValue(description.name, innerHTML);
        }
      });
    }
  }, []);

  const formats = [
    "size",
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "indent",
    "align",
    "background",
    "image",
    "color",
    "background-color",
    "link",
  ];

  useEffect(() => {
    if (createMode && !ticketID && !tID) {
      setFieldValue("title", "");
      setFieldValue("EstimatedStartDate", null);
      setFieldValue("EstimatedEndDate", null);
      setFieldValue("EstimatedTime", null);
      setFieldValue("ActualStartDate", null);
      setFieldValue("ActualEndDate", null);
      setFieldValue("ActualTime", null);
      setFieldValue("description", "");
      setFieldValue("externalReference", "");
    }
  }, []);

  const handleQuillChange = (html) => {
    if (touched[description.name]) handleChangeFlag(touched[description.name]);
    setFieldValue(description.name, html);
  };

  const {
    data: areasList,
    loading: areaLoading,
    tokenChange: areasWait,
    refetch: refetchAreas,
  } = useQueryAuth(GET_ALL_AREAS, "GetAreasList", {
    variables: {
      model: {
        enableFilters: false,
        projectGuid: pID,
      },
    },
    skip: !fetchAreas,
    isWait: fetchAreas,
  });

  const area_list =
    areasList &&
    areasList.GetAreasList &&
    areasList.GetAreasList.areasList &&
    areasList.GetAreasList.areasList.areas
      ? areasList.GetAreasList.areasList.areas
      : [];

  const { data: statusesList, loading: statusLoading } = useQueryAuth(
    GET_ALL_TICKET_STATUSES,
    "GetTicketsStatus",
    {
      variables: {
        model: {
          enableFilters: false,
          userID: storeUserGuid,
          tenantID: storeUserTenant,
          projectID: pID,
        },
      },
      skip: areasWait || !openModal,
      isWait: true,
    }
  );

  const ticket_status_list =
    statusesList &&
    statusesList.GetTicketsStatus &&
    statusesList.GetTicketsStatus.ticketStatusesList.ticketStatuses &&
    statusesList.GetTicketsStatus.ticketStatusesList.ticketStatuses
      ? statusesList.GetTicketsStatus.ticketStatusesList.ticketStatuses
      : [];

  useEffect(() => {
    const link = document.querySelector(".ql-link");
    const clean = document.querySelector(".ql-clean");
    const tooltip = document.querySelector(".tooltip-link");
    const tooltipClean = document.querySelector(".tooltip-clean");

    const input = document.querySelector(".ql-tooltip input");

    if (link != null) {
      link.addEventListener("mouseover", () => {
        tooltip.style.display = "block";
      });

      link.addEventListener("mouseout", () => {
        tooltip.style.display = "none";
      });
      link.addEventListener("click", () => {
        input.value = "http://";
      });
    }
    if (clean != null && tooltipClean != null) {
      clean.addEventListener("mouseover", () => {
        tooltipClean.style.display = "block";
      });

      clean.addEventListener("mouseout", () => {
        tooltipClean.style.display = "none";
      });
    }
  }, []);
  useEffect(() => {
    if (createMode && ticket_status_list?.length > 0)
      setFieldValue(
        status.name,
        ticket_status_list?.filter((s) => s.state?.mnemonic == "new")[0]
      );
  }, [ticket_status_list?.length]);

  const EstimatedTimeFields = () => {
    const fields = [
      {
        label: t(EstimatedStartDate.label),
        value: EstimatedStartDateV,
      },
      {
        label: t(EstimatedEndDate.label),
        value: EstimatedEndDateV,
      },
      { label: t(EstimatedTime.label), value: EstimatedTimeV },
    ];

    return (
      <Grid
        container
        spacing={2}
        mt={0.5}
        mb={0.5}
        ml={0}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {fields.map((field, index) => (
          <React.Fragment key={index}>
            <Grid
              item
              xs={12}
              sm={5}
              md={5}
              lg={5}
              xl={5}
              sx={{ margin: "0", padding: "0" }}
            >
              <p className="timeFields">{field.label}</p>
            </Grid>
            <Grid
              item
              xs={12}
              sm={7}
              md={7}
              lg={7}
              xl={7}
              sx={{ margin: "0", padding: "0" }}
            >
              <p className="timeFieldsV">{field.value}</p>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    );
  };

  const ActualTimeFields = () => {
    const fields = [
      {
        label: t(ActualStartDate.label),
        value: ActualStartDateV,
      },
      {
        label: t(ActualEndDate.label),
        value: ActualEndDateV,
      },
      { label: t(ActualTime.label), value: ActualTimeV },
    ];

    const isActualLate =
      EstimatedTimeV !== null &&
      ActualTimeV !== null &&
      parseFloat(ActualTimeV) > parseFloat(EstimatedTimeV) &&
      parseFloat(EstimatedTimeV) !== 0;

    const isStartDateLate =
      EstimatedStartDateV !== null &&
      ActualStartDateV !== null &&
      ActualStartDateV > EstimatedStartDateV;

    const isEndDateLate =
      EstimatedEndDateV !== null &&
      ActualEndDateV !== null &&
      ActualEndDateV > EstimatedEndDateV;

    return (
      <Grid
        container
        spacing={2}
        mt={0.5}
        mb={0.5}
        ml={0}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {fields.map((field, index) => (
          <React.Fragment key={index}>
            <Grid
              item
              xs={12}
              sm={5}
              md={5}
              lg={5}
              xl={5}
              sx={{ margin: "0", padding: "0" }}
            >
              <p className="timeFields">{field.label}</p>
            </Grid>
            <Grid
              item
              xs={12}
              sm={7}
              md={7}
              lg={7}
              xl={7}
              sx={{
                margin: "0",
                padding: "0",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <p className="timeFieldsV">{field.value}</p>
              {index === 2 && isActualLate ? (
                <AlertIcon
                  color="#ff2825"
                  width="20"
                  height="20"
                  title="Late"
                />
              ) : null}
              {index === 0 && isStartDateLate ? (
                <AlertIcon
                  color="#ffbf00"
                  width="20"
                  height="20"
                  title="Late"
                />
              ) : null}
              {index === 1 && isEndDateLate ? (
                <AlertIcon
                  color="#ffbf00"
                  width="20"
                  height="20"
                  title="Late"
                />
              ) : null}
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    );
  };

  const statusContainer = () => {
    switch (syncedV) {
      case true:
        return (
          <Grid
            style={{
              //top: 0,
              right: 0,
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-rotate-clockwise"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#00b341"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M4.05 11a8 8 0 1 1 .5 4m-.5 5v-5h5" />
            </svg>
            Synced
          </Grid>
        );
      case false:
        return null;
      default:
        return null;
    }
  };

  const handleStatusButtonClick = (event) => {
    setAnchorElStatus(event.currentTarget);
  };

  const handleStatusMenuItemClick = (value) => {
    setAnchorElStatus(null);
    if (createMode) {
      setTouched({ ...touched, [status.name]: true });
      handleChangeFlag({ ...touched, [status.name]: true });
    }
    // setFieldValue(status.name, value);
    values.status = value;
    if (
      updateTicketQuick &&
      !createMode &&
      !sessionStorage.getItem("unsavedChanges")
    )
      updateTicketQuick(value?.ticketStatusGuid);
    else if (
      handleSubmit &&
      !createMode &&
      sessionStorage.getItem("unsavedChanges")
    ) {
      setTimeout(() => {
        handleSubmit(values);
      }, 1000);
    }
  };

  const handleStatusClose = () => {
    setAnchorElStatus(null);
  };

  const statusPicked = () => {
    return (
      <Grid
        style={{
          top: 0,
          right: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          flexDirection: "row",
        }}
      >
        <Tooltip title={t("task.tootlip.status")}>
          <Button
            onClick={handleStatusButtonClick}
            disabled={
              readOnly ||
              manageTimeEntry === "R" ||
              isEmployee === Mnemonics.ResourceTypes.External ||
              false
            }
            sx={{
              ":hover": { backgroundColor: "transparent !important" },
              padding: 0,
            }}
          >
            {componentDisplay({
              compName: "BackgroundStatus",
              compProps: { row: values, size: 12, length: 25, widthAuto: true },
            })}
          </Button>
          <Menu
            anchorEl={anchorElStatus}
            open={Boolean(anchorElStatus)}
            onClose={handleStatusClose}
          >
            {ticket_status_list?.map((option) => (
              <MenuItem
                key={option?.taskStatusGuid}
                onClick={() => handleStatusMenuItemClick(option)}
              >
                {" "}
                {option?.description}
              </MenuItem>
            ))}
          </Menu>
        </Tooltip>
      </Grid>
    );
  };
  const handleOpenAreaModal = () => {
    setTimeout(() => {
      setOpenAreaModal(true);
    }, 800);
  };

  return (
    <Box>
      <FormModal
        showModal={openAreaModal}
        setShowModal={(v) => setOpenAreaModal(v)}
        loading={false}
        refetch={refetchAreas}
        open={JSON.parse(sessionStorage.getItem("unsavedChanges"))}
        modifyFormFlag={modifyFormFlag}
        Child={AreasList}
      />
      <Grid
        container
        spacing={1}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={8.5}
          lg={8.5}
          xl={8.5}
          spacing={2}
          gap={1}
          className="grid-item"
        >
          <Grid container spacing={1}>
            <Grid container spacing={2}>
              <Grid
                container
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {createMode ? (
                  <Title style={{ padding: "10px 0", marginRight: "10px" }}>
                    {t("ticketForm.newTicket")}
                  </Title>
                ) : (
                  <Title style={{ padding: "10px 0", marginRight: "10px" }}>
                    {t("ticketForm.ticketInformation")} {phaseId}
                  </Title>
                )}

                {statusContainer()}

                <div style={{ marginLeft: "auto", marginTop: "0.5rem" }}>
                  {statusPicked()}
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                      autoFocus
                      fullWidth
                      value={titleV}
                      onChange={(e) => {
                        setFieldValue(title.name, e.target.value);
                      }}
                      label={t(title.label)}
                      name={title.name}
                      formData={formData}
                      required
                      disabled={readOnly}
                    />
                  </Grid>
                  <Tooltip
                    title={t("phase.tooltip.regularPayExternalResource")}
                  >
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <TextField
                        fullWidth
                        value={externalReferenceV}
                        onChange={(e) => {
                          setFieldValue(externalReference.name, e.target.value);
                        }}
                        label={t(externalReference.label)}
                        name={externalReference.name}
                        formData={formData}
                        disabled={readOnly ? true : false}
                      />
                    </Grid>
                  </Tooltip>

                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <AutoComplete
                      options={area_list}
                      getOptionLabel={(option) =>
                        option ? option.description : ""
                      }
                      renderOption={(props, option) => (
                        <li {...props} key={uuidv4()}>
                          {option.description}
                        </li>
                      )}
                      identifier={"areaGuid"}
                      disabled={readOnly}
                      value={areaV}
                      onOpen={() => setFetchAreas(true)}
                      onClose={() => setFetchAreas(false)}
                      onChange={(e, value) => {
                        setFieldValue(area.name, value);
                      }}
                      loading={areaLoading}
                      name={area.name}
                      label={t(area.label)}
                      formData={formData}
                      showDisplayAdd={
                        isEmployee === Mnemonics.ResourceTypes.Employee &&
                        manageSystemConfigurations === "M" &&
                        (areaV === null || areaV === undefined || areaV === "")
                      }
                      handleClickAdd={() => handleOpenAreaModal()}
                      // PopperComponent={(props) => (
                      //   <Popper {...props}>
                      //     {props.children}
                      //     <Grid
                      //       container
                      //       sx={{
                      //         backgroundColor: colors.pure_white,
                      //         display: "flex",
                      //         justifyContent: "end",
                      //       }}
                      //     >
                      //       <IconButton
                      //         color="primary"
                      //         sx={{
                      //           backgroundColor: colors.pure_white,
                      //           border: "none !important",
                      //           paddingRight: "5px",
                      //           "& :hover": { backgroundColor: "transparent" },
                      //         }}
                      //         onMouseDown={() => refetchAreas()}
                      //       >
                      //         <svg
                      //           xmlns="http://www.w3.org/2000/svg"
                      //           class="icon icon-tabler icon-tabler-refresh"
                      //           width="20"
                      //           height="20"
                      //           viewBox="0 0 24 24"
                      //           stroke-width="1"
                      //           stroke={"var(--color--light-logo-blue)"}
                      //           fill="none"
                      //           stroke-linecap="round"
                      //           stroke-linejoin="round"
                      //         >
                      //           <path
                      //             stroke="none"
                      //             d="M0 0h24v24H0z"
                      //             fill="none"
                      //           />
                      //           <path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -4v4h4" />
                      //           <path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4" />
                      //         </svg>
                      //       </IconButton>
                      //       <Button
                      //         color="primary"
                      //         sx={{
                      //           backgroundColor: colors.pure_white,
                      //           border: "none !important",
                      //           paddingRight: "15px",
                      //           color: "var(--color--light-logo-blue) !important",
                      //           "& :hover": { backgroundColor: "transparent" },
                      //         }}
                      //         onMouseDown={() => handleAddNewArea()}
                      //       >
                      //         {t("add.new")}
                      //       </Button>
                      //     </Grid>
                      //   </Popper>
                      // )}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  pt={2}
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  mt={2}
                  className={modal === true ? "quill-modal" : "quill-page"}
                >
                  <div
                    style={{ position: "relative" }}
                    onFocus={() =>
                      setTouched({ ...touched, [description.name]: true })
                    }
                  >
                    <Backdrop
                      sx={{
                        color: "#fff",
                        position: "absolute !important",
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                      }}
                      open={loadingImage}
                    >
                      <CircularProgress color="inherit" />
                    </Backdrop>
                    <ReactQuill
                      ref={quillRef}
                      name="body"
                      value={descriptionV}
                      onChange={handleQuillChange}
                      modules={modules}
                      formats={formats}
                      placeholder="Description"
                    />
                  </div>
                  <div
                    className="tooltip-link"
                    style={{
                      fontFamily: "poppins",
                      lineHeight: "21px",
                      backgroundColor: "#ffffff",
                      color: "#000",
                      borderRadius: "2px",
                      opacity: "0.8",
                      boxShadow: `0px 1px 4px ${colors.box_shadow_color}`,
                      padding: "13px !important",
                    }}
                  >
                    {t("tooltip.link")}
                  </div>
                  <div
                    className="tooltip-clean"
                    style={{
                      fontFamily: "poppins",
                      lineHeight: "21px",
                      backgroundColor: "#ffffff",
                      color: "#000",
                      borderRadius: "2px",
                      opacity: "0.8",
                      boxShadow: `0px 1px 4px ${colors.box_shadow_color}`,
                      padding: "13px !important",
                    }}
                  >
                    {t("tooltip.clean")}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
          <Grid container spacing={1}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                mt={1}
                className="flex_space-between_center"
              >
                <MiniSubtitle>{t("ticketForm.time")}</MiniSubtitle>
              </Grid>
              {EstimatedTimeFields()}
            </Grid>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                mt={2}
                style={{
                  borderTop: "1px solid #ccc",
                  paddingTop: "15px",
                  marginTop: "2rem",
                }}
                className="flex_space-between_center"
              >
                <MiniSubtitle>{t("taskform.actual")}</MiniSubtitle>
              </Grid>
              {ActualTimeFields()}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Main;
