import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useLayoutEffect,
} from "react";

//routing
import { useNavigate, useParams } from "react-router-dom";

//components
import DataTable from "../../../../components/Tables/DataTable";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";

//hooks
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useAuth from "../../../../hooks/useAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";

//graphql
import {
  GET_USER_TABLE_FILTERS,
  REMOVE_USER_TABLE_FILTERS,
  SAVE_USER_TABLE_FILTERS,
} from "../../../../graphql/userTableFilters";
import {
  DELETE_TASKS,
  GET_ALL_LIST_TASKS,
  GET_ALL_PARENT_TASKS,
  GET_ALL_TASKS_LIST,
  GET_TASK_BACKLOG_SUBSCRIPTION,
  MOVE_TASK,
} from "../../../../graphql/task";
import { GET_USER_TABLE_PREFERENCES } from "../../../../graphql/userTablePreferences";
import { GET_ALL_EMPLOYEES_DROPDOWN } from "../../../../graphql/employee";
import { GET_ALL_TASK_STATUSES } from "../../../../graphql/taskStatus";
import { GET_ALL_AREAS } from "../../../../graphql/areas";
import { GET_ALL_PROJECTS_DROPDOWN } from "../../../../graphql/project";
import { GET_ALL_TEAMS } from "../../../../graphql/team";

//editors
import { Parse } from "../../../../JSON.editor";

//mnemonic values
import Mnemonic from "../../../../Mnemonics.json";

//urls
import urls from "../../../../urls";

//external components
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
} from "@mui/material";

import ContainerCard from "../../../../components/Cards/ContainerCard";
import TaskTabsModal from "../../../../components/Modal/TaskTabsModal";
import UTC2Local, { formatDateTimeToString } from "../../../../UTC2Local";
import componentDisplay from "../../../../componentDisplay";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import { getFiltersObject } from "./filtersConfig";

import colors from "../../../../assets/theme/light/colors";
import {
  ExcelIcon,
  getOptionPriorityIcon,
  SubtaskIcon,
} from "../../../../components/Icon";

import ConfirmationModal from "../../../../components/Modal/ConfirmationModal";
import RoundedDatePicker from "../../../../components/DateField/RoundedDatePicker";
import { exportToExcel } from "../../../../utils/exportToExcel";
import { EXPORT_TO_EXCEL } from "../../../../graphql/reports";
import RoundedTextField from "../../../../components/TextFields/RoundedTextField";
import { day_month_year } from "../../../../utils/timezones";
import CustomButton from "../../../../components/CustomButton";

//Material React Table
import useColumnsConfig from "./_tableColumns";
import SavedFilters from "./_savedFilters";
import tableStyles from "./tableStyles";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { LOGOUT_USER } from "../../../../graphql/user";
import apiCall from "../../../../hooks/useRest";
import { saveToken } from "../../../../app/reducers/tokenSlice";
import { store } from "../../../../app/store";
import { useDispatch } from "react-redux";
import { set } from "date-fns/esm";
import { useSubscription } from "@apollo/client";
import { decodeUnicodeString } from "../../../../utils/decodeUnicodeString";
import { removeNullProps } from "../../../../utils/removeNullProps";

const TasksList = ({
  pageSize = null,
  parentGuid = null,
  parentName = null,
  handleCloseModal,
  fromSubtask = false,
  fromTenant = false,
  fromProject = false,
  fromTicket = false,
  fromProjectTasks = false,
  fromBacklog = false,
  fromDashboard = false,
  outSide = false,
  projectIDInherit = null,
  projectNameInherit = null,
  fromEmployeeAvailability = false,
  ...props
}) => {
  const { tID, tName, tkID, tkName, pName, pID } = useParams();

  const dispatch = useDispatch();
  const {
    tenant_project_tasks_form,
    my_project_tasks_form,
    tenant_project_direct_tasks_form,
    my_project_direct_tasks_form,
    project_backlog_tasks,
  } = urls;

  const {
    storeUserGuid,
    storeUserTenant,
    storeUserTeams,
    isEmployee,
    userRoles,
    isManager,
    jwtToken,
    logout,
  } = useAuth();

  const { isAdmin, manageTimeEntry } = userRoles;

  const { t } = useTranslation();

  const userTimezone = Intl.DateTimeFormat()
    .resolvedOptions()
    .timeZone.split("/")[0];
  const offset = new Date().getTimezoneOffset() / -60;

  const { enqueueSnackbar } = useSnackbar();
  const [re, setRe] = useState(false);
  const [de, setDe] = useState(false);
  const [data, setData] = useState([]);
  const [filterState, setFilterState] = useState([]);
  const [defaultFilters, setDefaultFilters] = useState(
    filterState && filterState.length > 0 ? filterState : {}
  );
  const [columns, setColumns] = useState([]);
  const [columnOrder, setColumnOrder] = useState(
    ["mrt-row-select", ...columns.map((c) => c.id)] //array of column ids (Initializing is optional as of v2.10.0)
  );
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");
  const DELAY_BEFORE_CHIPS = 2000;
  const [sortingUpdated, setSortingUpdated] = useState(false);

  const [taskID, setTaskID] = useState(null);
  const [taskName, setTaskName] = useState(null);
  const [projectID, setProjectID] = useState(projectIDInherit);
  const [projectName, setProjectName] = useState(projectNameInherit);
  const [openModal, setOpenModal] = useState(false);
  const [openExcelModal, setOpenExcelModal] = useState(false);
  const [type, setType] = useState(null);
  const [parent, setParent] = useState(null);
  const [fetchProjects, setFetchProjects] = useState(false);
  const [fetchTasks, setFetchTasks] = useState(false);
  const [fetchAreas, setFetchAreas] = useState(false);
  const [projectsList, setProjectsList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [taskList, setTaskList] = useState([]);
  const [openSavedFilters, setOpenSavedFilters] = useState(false);

  const [includeSubtasks, setIncludeSubtasks] = useState(false);

  const [selectedProject, setSelectedProject] = useState(null);
  const [assignedToMe, setAssignedToMe] = useState(pID ? false : true);
  const [assignedToMyTeam, setAssignedToMyTeam] = useState(pID ? false : true);
  const [myProjectTasks, setMyProjectTasks] = useState(pID ? true : false);
  const [onlyTasks, setOnlyTasks] = useState(true);
  const [onlySubtasks, setOnlySubtasks] = useState(true);
  const [doing, setDoing] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [showParents, setShowParents] = useState(pID ? true : false);
  const [rowSelection, setRowSelection] = useState({});
  const [sorting, setSorting] = useState([]);

  const [page, setPage] = useState(0);

  const [isError, setIsError] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [chipFilters, setChipFilters] = useState({
    showParents: pID ? true : false,
    assignedToMe: pID ? false : fromEmployeeAvailability ? false : true,
    assignedToMyTeam: pID ? false : fromEmployeeAvailability ? false : true,
    onlySubtasks: true,
    onlyTasks: true,
    myProjectTasks: pID ? true : false,
    doing: false,
  });


  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState(null);
  const [appliedFilters, setAppliedFilters] = useState(null);
  const [columnFilters, setColumnFilters] = useState([]);
  const [rowGuid, setRowGuid] = useState("");
  const [deleteIcon, setDeleteIcon] = useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20,
  });
  const [open, setOpen] = useState(false);
  const [fileName, setFileName] = useState("");
  const [timer, setTimer] = useState(null);
  // const timer = useRef(null);
  const count = useRef(0);
  const timerCount = useRef(0);
  const addNewSubtask = useRef(false);
  const nowDate = new Date();
  const [dateTo, setDateTo] = useState(new Date());
  const [dateFrom, setDateFrom] = useState(
    nowDate.setDate(nowDate.getDate() - 7)
  );
  const [showQuickFilters, setShowQuickFilters] = useState(() => {
    const savedState = localStorage.getItem("showQuickFilters");
    return savedState === "true";
  });
  const [movedRow, setMovedRow] = useState({});
  const [targetRow, setTargetRow] = useState({});
  const [pendingMove, setPendingMove] = useState(null);
  const [openMovedRowDialog, setOpenMovedRowDialog] = useState(false);
  const [savedFilters, setSavedFilters] = useState([]);
  const [selectedFilterGuid, setSelectedFilterGuid] = useState(null);
  const [filterGuidToBeRemoved, setFilterGuidToBeRemoved] = useState(null);
  const [response, setResponse] = useState([]);
  const [openDeleteFilterModal, setOpenDeleteFilterModal] = useState(false);

  const prioritiesList = [
    {
      label: Mnemonic.Priority.Low,
      value: Mnemonic.Priority.Low,
    },
    {
      label: Mnemonic.Priority.Medium,
      value: Mnemonic.Priority.Medium,
    },
    {
      label: Mnemonic.Priority.High,
      value: Mnemonic.Priority.High,
    },
  ];



  useSubscription(GET_TASK_BACKLOG_SUBSCRIPTION, {
    fetchPolicy: "no-cache",
    variables: {
      model: {
        keys: !chipFilters?.showParents
          ? {
            page: page + 1 || 1,
            pageSize: rowsPerPage || 20,
          }
          : null,
        showParentsHierarchy: chipFilters?.showParents,
        enableFilters: true,
        onlyTasks: chipFilters?.onlyTasks,
        onlySubtasks: chipFilters?.onlySubtasks,
        highPriority: chipFilters?.doing,
        myProjectsTasks: chipFilters?.myProjectTasks,
        myTasks: chipFilters?.assignedToMe,
        myTeamsTasks: chipFilters?.assignedToMyTeam,
        projectID: fromTicket ? pID : fromProjectTasks ? pID : null,
        ticketID: fromTicket ? tkID : null,
        onGoingTasks: false,
        filters: defaultFilters,
        userID: storeUserGuid,
        tenantID: storeUserTenant,
        keyWord: search,
        orderDirection: order,
        orderColumn: orderBy,
        offset: offset,
      },
    },
    skip: showParents || chipFilters?.showParents,
    isWait: !(showParents && chipFilters?.showParents),
    onData: ({ data }) => {
      const dataObj = data?.data?.filteredTasksTreeGQL?.tasksTreeResponse;
      setData(dataObj?.thisTaskViews || []);
      setResponse(data?.data);
      setRowCount(dataObj?.totalNumberOfRecords);
    },
  });

  const { mfunction: getAllTasksList, loading: tasksListLoading } =
    useLazyQueryAuth(GET_ALL_TASKS_LIST, "GetFilteredTasks");
  const fetchTableData = useCallback(
    (
      page = page || 0,
      pageSize = rowsPerPage || 20,
      order = "",
      orderBy = "",
      search = "",
      filters = null,
      chipFilters = null
    ) => {
      getAllTasksList(
        {
          variables: {
            model: {
              keys: !chipFilters?.showParents
                ? {
                  page: page + 1 || 1,
                  pageSize: pageSize || 20,
                }
                : null,
              showParentsHierarchy: chipFilters?.showParents,
              enableFilters: true,
              onlyTasks: chipFilters?.onlyTasks,
              onlySubtasks: chipFilters?.onlySubtasks,
              highPriority: chipFilters?.doing,
              myProjectsTasks: chipFilters?.myProjectTasks,
              myTasks: chipFilters?.assignedToMe,
              myTeamsTasks: chipFilters?.assignedToMyTeam,
              projectID: fromTicket ? pID : fromProjectTasks ? pID : null,
              ticketID: fromTicket ? tkID : null,
              onGoingTasks: false,
              filters: filters,
              userID: storeUserGuid,
              tenantID: storeUserTenant,
              keyWord: search,
              orderDirection: order,
              orderColumn: orderBy,
              offset: offset,
            },
            skip: showParents,
            isWait: true,
          },
        },
        (response) => {
          setData(
            response?.GetFilteredTasks?.tasksTreeResponse?.thisTaskViews || []
          );
          setResponse(response);
          setRowCount(
            response?.GetFilteredTasks?.tasksTreeResponse?.totalNumberOfRecords
          );
        }
      );
    },
    []
  );


  const { mfunction: getData, loading: dataLoading } = useLazyQueryAuth(
    EXPORT_TO_EXCEL,
    "ProjectExportTimeEntries"
  );

  const { mfunction: deleteRecords, loading: deleteLoading } = useMutationAuth(
    DELETE_TASKS,
    "DeleteListOfTasks"
  );
  const { mfunction: logsOutUser } = useMutationAuth(LOGOUT_USER, "LogoutUser");

  const backendURL = process.env.REACT_APP_REST_URL;
  const {
    data: employeesData,
    loading: employeeLoading,
    tokenChange: employeeWait,
  } = useQueryAuth(GET_ALL_EMPLOYEES_DROPDOWN, "GetCompanyUsersList", {
    variables: {
      model: {
        enableFilters: false,
        userID: storeUserGuid,
        tenantID: fromTenant ? null : storeUserTenant,
        showAll: true,
        // isManagerUsersList: true
      },
    },
    // skip: !fetchEmployees,
    // isWait: fetchEmployees,
  });

  const employeesList =
    employeesData?.GetCompanyUsersList?.usersList?.employees || [];

  const {
    data: statusData,
    loading: statusLoading,
    tokenChange: statusWait,
  } = useQueryAuth(GET_ALL_TASK_STATUSES, "GetTaskStatuses", {
    variables: {
      model: {
        enableFilters: false,
        tenantID: fromTenant ? null : storeUserTenant,
      },
    },
    skip: employeeWait,
    isWait: true,
    // skip: !fetchTaskStatuses,
    // isWait: fetchTaskStatuses,
  });

  const taskStateList = statusData?.GetTaskStatuses?.tasksStatus || [];

  const {
    data: teamsData,
    loading: teamsLoading,
    tokenChange: teamsWait,
  } = useQueryAuth(GET_ALL_TEAMS, "GetTeamsList", {
    variables: {
      model: {
        tenantGuid: storeUserTenant,
        userID:
          isEmployee === Mnemonic.ResourceTypes.Employee ? null : storeUserGuid,
      },
    },
    skip: employeeWait || statusWait,
    isWait: true,
    // skip: !fetchTeams,
    // isWait: fetchTeams,
  });

  const teams_list = teamsData?.GetTeamsList?.teamsList?.teams || [];

  useEffect(() => {
    if (fromDashboard && !fromBacklog) {
      const objectIndexStatusIDs = filterState.findIndex(
        (obj) => obj.property === "statusIDs"
      );

      // statusIDs
      const filteredStatusArray = taskStateList.filter(
        (stat) => stat.taskState.mnemonic !== Mnemonic.TaskState.done
      );
      const filteredStatusGuids = filteredStatusArray.map(
        (stat) => stat.taskStatusGuid
      );

      if (filteredStatusArray?.length > 0) {
        if (filteredStatusGuids.length > 0) {
          if (objectIndexStatusIDs < 0) {
            setDefaultFilters({
              ...defaultFilters,
              statusIDs: filteredStatusGuids,
            });
            const statusObj = {
              value: filteredStatusGuids,
              property: "statusIDs",
            };
            setFilterState([...filterState, statusObj]);
            // } else {
            //   const val =
            //     filterState[objectIndexStatusIDs].value == null
            //       ? null
            //       : [filterState[objectIndexStatusIDs].value];
            //   const statusObj = { value: val, property: "statusIDs" };
            //   setDefaultFilters({ ...defaultFilters, statusIDs: val });

            //   setFilterState([...filterState, statusObj]);
          }
        }
      }
    }


    if (fromEmployeeAvailability === true && filterState) {
      setDefaultFilters({ ...defaultFilters, unassignedTasks: true });
      setFilterState([...filterState, { value: true, property: "unassignedTasks" }]);
    }

    if (pID != null && !fromBacklog) {
      setDefaultFilters({ ...defaultFilters, projectID: pID });
    }
  }, [taskStateList.length, de]);

  useEffect(() => {
    if (fromDashboard && !fromBacklog) {
      const objectIndexTeamsIDs = filterState.findIndex(
        (obj) => obj.property === "teamsIDs"
      );

      const objectIndexEmployeeIDs = filterState.findIndex(
        (obj) => obj.property === "employeeIDs"
      );

      const objectIndexBillableIDs = filterState.findIndex(
        (obj) => obj.property === "billable"
      );

      const objectIndexNonBillableIDs = filterState.findIndex(
        (obj) => obj.property === "nonBillable"
      );

      const billableValue = {
        value: true,
        property: "billable",
      };
      const nonBillableValue = {
        value: true,
        property: "nonBillable",
      };

      // teamIDs
      const filteredTeams = storeUserTeams?.map((x) => x.team);
      const filteredTeamsGuids = filteredTeams.map((t) => t.teamGuid);

      if (filteredTeamsGuids.length > 0 && filteredTeams?.length > 0) {
        if (objectIndexTeamsIDs < 0 && objectIndexEmployeeIDs < 0) {
          if (objectIndexBillableIDs < 0 && objectIndexNonBillableIDs < 0) {
            setDefaultFilters({
              ...defaultFilters,
              billable: true,
              nonBillable: true,
              teamsIDs: filteredTeamsGuids,
              employeeIDs: [storeUserGuid],
            });
            setFilterState([
              ...filterState,
              { value: filteredTeamsGuids, property: "teamsIDs" },
              { value: [storeUserGuid], property: "employeeIDs" },
              billableValue,
              nonBillableValue,
            ]);
          } else {
            setDefaultFilters({
              ...defaultFilters,
              teamsIDs: filteredTeamsGuids,
              employeeIDs: [storeUserGuid],
            });
            setFilterState([
              ...filterState,
              { value: filteredTeamsGuids, property: "teamsIDs" },
              { value: [storeUserGuid], property: "employeeIDs" },
            ]);
          }
        } else if (objectIndexTeamsIDs < 0 && filteredTeams?.length > 0) {
          if (objectIndexBillableIDs < 0 && objectIndexNonBillableIDs < 0) {
            setDefaultFilters({
              ...defaultFilters,
              billable: true,
              nonBillable: true,
              teamsIDs: filteredTeamsGuids,
            });
            setFilterState([
              ...filterState,
              { value: filteredTeamsGuids, property: "teamsIDs" },
              billableValue,
              nonBillableValue,
            ]);
          }
        } else if (objectIndexEmployeeIDs < 0) {
          if (objectIndexBillableIDs < 0 && objectIndexNonBillableIDs < 0) {
            setDefaultFilters({
              ...defaultFilters,
              billable: true,
              nonBillable: true,
              employeeIDs: [storeUserGuid],
            });
            setFilterState([
              ...filterState,
              { value: [storeUserGuid], property: "employeeIDs" },
              billableValue,
              nonBillableValue,
            ]);
          }
          // } else {
          //   const val =
          //     filterState[objectIndexTeamsIDs].value == null
          //       ? null
          //       : [filterState[objectIndexTeamsIDs].value];
          //   const teamsObj = { value: val, property: "teamsIDs" };
          //   setDefaultFilters({ ...defaultFilters, teamsIDs: val });

          //   setFilterState([...filterState, teamsObj]);
        }
      } else if (objectIndexEmployeeIDs < 0) {
        if (objectIndexBillableIDs < 0 && objectIndexNonBillableIDs < 0) {
          setDefaultFilters({
            ...defaultFilters,
            billable: true,
            nonBillable: true,
            employeeIDs: [storeUserGuid],
          });
          setFilterState([
            ...filterState,
            { value: [storeUserGuid], property: "employeeIDs" },
            billableValue,
            nonBillableValue,
          ]);
        }
      }
    } else {
      setDefaultFilters({
        billable: true,
        nonBillable: true,
        unassignedTasks: false,
      });
      setFilterState([
        { value: false, property: "unassignedTasks" },
        {
          value: true,
          property: "billable",
        },
        {
          value: true,
          property: "nonBillable",
        }
      ]);
    }
  }, [de]);

  const {
    data: projectData,
    loading: projectLoading,
    tokenChange: projectWait,
  } = useQueryAuth(GET_ALL_PROJECTS_DROPDOWN, "GetAllProjects", {
    variables: {
      model: {
        enableFilters: false,
        tenantID: fromTenant ? null : storeUserTenant,
        userID: storeUserGuid,
      },
    },
    skip: employeeWait || statusWait || teamsWait,
    isWait: true,
    // skip: !fetchProjects,
    // isWait: fetchProjects,
  });

  useEffect(() => {
    if (projectData)
      setProjectsList(
        projectData?.GetAllProjects?.projectsList?.projectViews || []
      );
  }, [projectData]);

  const {
    data: areasList,
    loading: areaLoading,
    tokenChange: areaWait,
  } = useQueryAuth(GET_ALL_AREAS, "GetAreasList", {
    variables: {
      model: {
        enableFilters: false,
        projectGuid: pID || selectedProject?.projectGuid,
      },
    },
    skip: !(fetchAreas && (selectedProject !== null || pID)),
    isWait: fetchAreas && (selectedProject !== null || pID),
  });

  useEffect(() => {
    if (areasList)
      setAreaList(
        areasList &&
          areasList.GetAreasList &&
          areasList.GetAreasList.areasList &&
          areasList.GetAreasList.areasList.areas
          ? areasList.GetAreasList.areasList.areas
          : []
      );
  }, [areasList]);

  const {
    data: tasksList,
    loading: taskLoading,
    tokenChange: tasksWait,
  } = useQueryAuth(GET_ALL_PARENT_TASKS, "GetTasks", {
    variables: {
      model: {
        tenantID: storeUserTenant,
        enableFilters: false,
        onGoingTasks: true,

        userID: storeUserGuid,
        projectID: selectedProject?.projectGuid,
      },
    },
    skip: !(fetchTasks && selectedProject !== null),
    isWait: fetchTasks && selectedProject !== null,
  });

  useEffect(() => {
    if (tasksList)
      setTaskList(
        tasksList && tasksList.GetTasks && tasksList.GetTasks.tasks
          ? tasksList.GetTasks.tasks
          : []
      );
  }, [tasksList]);

  const {
    data: getFilters,
    loading: filterLoading,
    tokenChange: filtersWait,
    refetch: refetchFilters,
  } = useQueryAuth(GET_USER_TABLE_FILTERS, "GetUserTableFilters", {
    variables: {
      model: {
        userID: storeUserGuid,
        tableName: Mnemonic.Tables.TasksBacklog,
        projectID: pID ? pID : null,
      },
    },
    skip: employeeWait || statusWait || teamsWait || projectWait,
    isWait: true,
  });

  const {
    loading: getColumnsLoading,
    tokenChange: columnsWait,
    refetch,
  } = useQueryAuth(
    GET_USER_TABLE_PREFERENCES,
    "GetUserTablePreferences",
    {
      variables: {
        model: {
          table: Mnemonic.Tables.TasksBacklog,
          userID: storeUserGuid,
        },
      },
      skip:
        employeeWait || statusWait || teamsWait || projectWait || filtersWait,
      isWait: true,
    },
    {
      onCompleted: (response) => {
        let col = response?.GetUserTablePreferences?.columns || null;

        if (col) {
          col = decodeUnicodeString(col);

          // Parse the JSON string to a JSON object
          const parsed = JSON.parse(col, (key, value) =>
            Parse(key, value, UTC2Local, componentDisplay, null, null, null, formatDateTimeToString)
          );

          if (parsed.columns) {
            let cols = parsed.columns;

            cols.map(c => {
              c = removeNullProps(c);
              return c;
            })
            setColumns(cols);
          }
          if (parsed.columns) setColumnOrder(parsed.columns.map((x) => x.id));
          if (parsed.defaultSort) setOrderBy(parsed.defaultSort);
          if (parsed.defaultSortDirection)
            setOrder(parsed.defaultSortDirection);
        }
        if (!pID) {
          const task_filter = JSON.parse(localStorage.getItem("task_filter"));
          const task_filter_guid = JSON.parse(
            localStorage.getItem("task_filter_guid")
          );

          if (task_filter && task_filter_guid)
            handleApplyFilter(task_filter_guid, task_filter);
        } else {
          const project_task_filter = JSON.parse(localStorage.getItem("project_task_filter"));
          const project_task_filter_guid = JSON.parse(
            localStorage.getItem("project_task_filter_guid")
          );

          if (project_task_filter && project_task_filter_guid)
            handleApplyFilter(project_task_filter_guid, project_task_filter);
        }
      },
    }
  );



  useEffect(() => {
    if (getFilters)
      setSavedFilters(
        getFilters?.GetUserTableFilters?.userTableFiltersList || []
      );
  }, [getFilters]);

  const { cfunction: moveTaskData } = useMutationAuth(MOVE_TASK, "MoveTask");
  const { cfunction: saveUserTableFiltersData } = useMutationAuth(
    SAVE_USER_TABLE_FILTERS,
    "SaveUserTableFilters"
  );
  const { cfunction: removeUserTableFiltersData } = useMutationAuth(
    REMOVE_USER_TABLE_FILTERS,
    "RemoveUserTableFilters"
  );

  const handleSubmit = (movedRow, targetRow, actions) => {
    moveTaskData(
      {
        variables: {
          model: {
            taskGuid: movedRow?.id,
            parentGuid: targetRow?.id,
            ticketGuid: targetRow?.ticketGuid,
            projectGuid: targetRow?.projectGuid,
            tenantGuid: storeUserTenant,
          },
        },
      },
      (response) => {
        if (
          response["MoveTask"].errorCode !== null &&
          response["MoveTask"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["MoveTask"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000,
          });
        } else {
          enqueueSnackbar(t("taskForm.movedSuccessfully"), {
            variant: "success",
          });
          handleRefetchTree();
          sessionStorage.removeItem("unsavedChanges");
          if (handleClose) handleClose();
        }
      },
      (error) => { }
    );
  };

  const handleSaveUserTableFilters = (
    filter,
    name = null,
    newFilter = true
  ) => {
    saveUserTableFiltersData(
      {
        variables: {
          model: {
            filterID: newFilter ? null : selectedFilterGuid,
            userID: storeUserGuid,
            projectID: pID,
            filterName: name,
            tableName: Mnemonic.Tables.TasksBacklog,
            tableFilters: filter,
          },
        },
      },
      (response) => {
        if (
          response["SaveUserTableFilters"].errorCode !== null &&
          response["SaveUserTableFilters"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["SaveUserTableFilters"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000,
          });
        } else {
          enqueueSnackbar(t("task.list.savedSuccessfully"), {
            variant: "success",
          });
          const guid = response["SaveUserTableFilters"]?.userTableFilters?.userTableFiltersGuid
          setSelectedFilterGuid(guid);
          refetchFilters();
          setOpenSavedFilters(true);
          if (newFilter === true) {
            if (!pID) {
              localStorage.setItem("task_filter", JSON.stringify(filter));
              localStorage.setItem("task_filter_guid", JSON.stringify(guid));
            }
            else {
              localStorage.setItem("project_task_filter", JSON.stringify(filter));
              localStorage.setItem("project_task_filter_guid", JSON.stringify(guid));
            }
          }
          return true;
        }
      },
      (error) => { }
    );
  };
  const handleRemoveUserTableFilters = () => {
    removeUserTableFiltersData(
      {
        variables: {
          model: {
            filterID: filterGuidToBeRemoved,
            userID: storeUserGuid,
            projectID: pID
          },
        },
      },
      (response) => {
        if (
          response["RemoveUserTableFilters"].errorCode !== null &&
          response["RemoveUserTableFilters"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["RemoveUserTableFilters"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000,
          });
        } else {
          enqueueSnackbar(t("task.list.deletedSuccessfully"), {
            variant: "success",
          });
          setSelectedFilterGuid(null);
          if (!pID) {
            localStorage.removeItem("task_filter");
            localStorage.removeItem("task_filter_guid");
          }
          else {
            localStorage.removeItem("project_task_filter");
            localStorage.removeItem("project_task_filter_guid");
          }
          if (selectedFilterGuid === filterGuidToBeRemoved) {
            setFilterGuidToBeRemoved(null);
            setDefaultFilters(null);
            setFilterState([]);
            setChipFilters({
              showParents: pID ? true : false,
              assignedToMe: true,
              assignedToMyTeam: true,
              onlySubtasks: true,
              onlyTasks: true,
              myProjectTasks: false,
              doing: false,
            });
            setDe((v) => !v);
          }
          refetchFilters();
        }
      },
      (error) => { }
    );
  };
  // const displayFilters = () => {
  //   filtersObject.forEach(filter => {
  //     let filterName = filter.name;
  //     if(defaultFilters[filterName] != undefined) {
  //       console.log(defaultFilters[filterName]);
  //       filter.options?.filter(x => )
  //     }
  //     // filtersToBeDisplayed
  //   })
  // }

  // useEffect(() => {
  //   displayFilters();
  // }, [])


  useEffect(() => {
    if (sorting) {
      setOrder(sorting[0]?.desc ? "desc" : "asc");
      setOrderBy(sorting[0]?.id);
      setSortingUpdated(true);
    }
  }, [sorting]);

  const updateToken = (updatedToken) => {
    if (updatedToken) dispatch(saveToken(updatedToken));
  };


  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }

    setTimer(
      setTimeout(() => {
        if (!columnsWait) {
          chipFilters?.showParents ? getTreeData(
            chipFilters,
            sorting,
            rowCount,
            search,
            defaultFilters,
            pagination
          ) :
            fetchTableData(
              page,
              rowsPerPage,
              order,
              orderBy,
              search,
              defaultFilters,
              chipFilters,
            );
        }
      }, DELAY_BEFORE_CHIPS)
    );
    setSortingUpdated(false);
  }, [chipFilters]);



  useEffect(() => {
    if (!columnsWait && showParents) {
      getTreeData(
        chipFilters,
        sorting,
        rowCount,
        search,
        defaultFilters,
        pagination
      );
    }
  }, [
    columnsWait,
    order,
    orderBy,
    pagination?.pageSize,
    pagination?.pageIndex,
    re,
    sortingUpdated,

  ]);

  useEffect(() => {
    if (!columnsWait && !showParents) {
      fetchTableData(
        page,
        rowsPerPage,
        order,
        orderBy,
        search,
        defaultFilters,
        chipFilters
      );
    }
  }, [columnsWait, order, orderBy, page, rowsPerPage, sortingUpdated]);

  async function getTreeData(
    chipFilters = null,
    sorting = "",
    rowCount = 0,
    search = "",
    defaultFilters = null,
    pagination,
    token = null
  ) {
    var model = {
      keys: !chipFilters?.showParents
        ? {
          page: pagination?.pageIndex + 1 || 1,
          pageSize: pagination?.pageSize || 20,
        }
        : null,
      showParentsHierarchy: chipFilters?.showParents,
      enableFilters: true,
      onlyTasks: chipFilters?.onlyTasks,
      onlySubtasks: chipFilters?.onlySubtasks,
      highPriority: chipFilters?.doing,
      myProjectsTasks: chipFilters?.myProjectTasks,
      myTasks: chipFilters?.assignedToMe,
      myTeamsTasks: chipFilters?.assignedToMyTeam,
      projectID: fromTicket ? pID : fromProjectTasks ? pID : null,
      ticketID: fromTicket ? tkID : null,
      onGoingTasks: false,
      filters: defaultFilters,
      userID: storeUserGuid,
      tenantID: storeUserTenant,
      keyWord: search,
      orderDirection: order,
      orderColumn: orderBy,
      offset: offset,
    };

    if (!isFetching) setLoading(true);

    const response = await apiCall(
      backendURL + `/Task/GetFilteredTasksTree`,
      "POST",
      model,
      logsOutUser,
      logout,
      updateToken,
      enqueueSnackbar,
      t
    );

    if (response) {
      setData(response?.data?.thisTaskViews || []);
      setRowCount(response?.data?.totalNumberOfRecords || 0);
    }

    setLoading(false);
  }

  const handleRefresh = () => {
    showParents
      ? getTreeData(
        chipFilters,
        sorting,
        rowCount,
        search,
        defaultFilters,
        pagination
      )
      : fetchTableData(
        page || 0,
        rowsPerPage || 20,
        order,
        orderBy,
        search,
        defaultFilters || null,
        chipFilters
      );
  };

  const filtersObject = getFiltersObject(
    (fromProject = !fromBacklog),
    projectsList,
    employeesList,
    taskStateList,
    teams_list,
    teamsLoading,
    projectLoading,
    employeeLoading,
    statusLoading,
    isAdmin,
    isManager,
    defaultFilters,
    filterState,
    areaList,
    areaLoading,
    taskList,
    taskLoading,
    setFetchProjects,
    setFetchTasks,
    setFetchAreas,
    setSelectedProject,
    selectedProject,
    prioritiesList
    // setFetchTaskStatuses,
    // setFetchTeams,
    // setFetchEmployees
  );

  const rows = showParents
    ? data?.GetTasksByTicket?.tasksList?.taskViews
    : response?.GetFilteredTasks?.tasksTreeResponse?.thisTaskViews;

  const numberOfRecords = showParents
    ? data?.GetTasksByTicket?.tasksList?.totalNumberOfRecords
    : response?.GetFilteredTasks?.tasksTreeResponse?.totalNumberOfRecords;

  function removeTaskByGuid(tasks, guidsArray) {
    return tasks?.filter((task) => {
      const hasMatchingGuid = guidsArray.includes(task.task.guid);
      if (hasMatchingGuid) {
        return false;
      }
      if (task.subtasks)
        task.subtasks = removeTaskByGuid(task.subtasks, guidsArray);
      return true;
    });
  }

  const handleDelete = () => {
    const guidArray = Object.keys(rowGuid);

    deleteRecords(
      {
        variables: {
          model: {
            list: guidArray,
          },
        },
      },
      (response) => {
        enqueueSnackbar(t("task.list.delete.success"), {
          variant: "success",
        });
        if (pID) setData(removeTaskByGuid(data, guidArray));
        else {
          const dataArr = data.filter((d) => !guidArray.includes(d.task.guid));
          setData(dataArr);
        }
        // !showParents &&
        //   fetchTableData(
        //     page || 0,
        //     rowsPerPage || 20,
        //     order,
        //     orderBy,
        //     search,
        //     defaultFilters || null,
        //     chipFilters
        //   );
        setSelected([]);
        setDeleteIcon(false);
        setRowSelection({});
        setRowGuid("");
        if (!pID) {
          setPage(0);
          setRe((v) => !v);
        }
      }
    );
  };

  const handleOpenModal = (row) => {
    const rowID = Object.keys(rowGuid)[0];
    if (fromSubtask) {
      handleCloseModal();
    }
    setTaskID(fromSubtask && !row ? null : row?.task?.taskGuid);
    setProjectID(
      fromSubtask ? projectID : row?.task?.ticket?.project?.projectGuid
    );
    setTaskName(fromSubtask && !row ? null : row?.task?.title);
    setProjectName(
      fromSubtask
        ? projectName
        : row?.task?.ticket?.project?.title
          ? row?.task?.ticket?.project?.title
          : data?.map((x) => x.task).filter((x) => x.guid == rowID)[0]
            ?.projectName
            ? data?.map((x) => x.task).filter((x) => x.guid == rowID)[0]
              ?.projectName
            : null
    );
    setType(fromSubtask ? "subtask" : "task");
    addNewSubtask.current = fromSubtask;
    setParent({
      taskGuid: parentGuid,
      title: parentName,
    });
    setTimeout(() => {
      setOpenModal(true);
    }, 500);
  };

  const findProjectDetails = (data, rowID) => {
    for (let item of data) {
      if (item?.task?.guid === rowID) {
        return {
          projectGuid: item.task.projectGuid,
          projectName: item.task.projectName,
          title: item.task.title,
        };
      }

      if (item.subtasks && item.subtasks.length > 0) {
        for (let subtask of item.subtasks) {
          if (subtask?.task?.guid === rowID) {
            return {
              projectGuid: subtask.task.projectGuid,
              projectName: subtask.task.projectName,
              title: subtask.task.title,
            };
          }

          // Recursive search in deeper subtasks
          const result = findProjectDetails(subtask?.subtasks || [], rowID);
          if (result) {
            return result;
          }
        }
      }
    }

    return null;
  };

  const handleAddNew = (type) => {
    if (type === "subtask") {
      const rowID = Object.keys(rowGuid)[0];

      const projectDetails = findProjectDetails(data, rowID);

      if (projectDetails) {
        const { projectGuid, projectName, title } = projectDetails;

        setTaskID(null);
        setProjectID(projectGuid);
        setTaskName(null);
        setProjectName(projectName);
        setType(type);
        setParent({ taskGuid: rowID, title: title });
        setTimeout(() => {
          setOpenModal(true);
        }, 500);
      } else {
        setTaskID(null);
        setProjectID(null);
        setTaskName(null);
        setProjectName(null);
        setType(type);
        setParent(null);
        setTimeout(() => {
          setOpenModal(true);
        }, 500);
      }
    } else {
      setTaskID(null);
      setProjectID(null);
      setTaskName(null);
      setProjectName(null);
      setType(type);
      setParent(null);
      setTimeout(() => {
        setOpenModal(true);
      }, 500);
    }
  };

  const customButtons = [
    {
      label: "dashboard.subtask.addNew",
      handleClick: () => {
        handleAddNew("subtask");
      },
      condition:
        (Object.keys(rowSelection).length === 0 ||
          Object.keys(rowSelection).length === 1) &&
        (!selected || selected.length === 0) &&
        isEmployee !== Mnemonic.ResourceTypes.External,
      icon: true,
      iconButton: <SubtaskIcon color={colors.light_logo_blue} />,
    },
    {
      label: "dashboard.task.addNew",
      handleClick: () => {
        handleAddNew("task");
      },
      condition:
        Object.keys(rowSelection).length === 0 &&
        (!selected || selected.length === 0) &&
        isEmployee !== Mnemonic.ResourceTypes.External &&
        manageTimeEntry === "M" &&
        !fromSubtask,
      icon: true,
      iconButton: (
        <AddCircleRoundedIcon
          sx={{
            color: colors.light_logo_blue,
            fontSize: "28px !important",
          }}
        />
      ),
    },
  ];

  function disableDates(date) {
    const oneDayBefore = new Date(dateFrom);
    oneDayBefore.setDate(oneDayBefore?.getDate() - 1);
    return date < oneDayBefore;
  }

  const handleValidateDates = (value) => {
    if (timer) {
      //if timer is set, we empty it so it's not called and wait another delay
      clearTimeout(timer);
      setTimer(null);
    }


    //set timer for after 2 seconds of wait
    setTimer(
      setTimeout(() => {
        const oneDayBefore = new Date(dateFrom);
        oneDayBefore.setDate(oneDayBefore?.getDate() - 1);
        if (value < oneDayBefore)
          enqueueSnackbar(t("employeeTimesheet.invalid.date"), {
            variant: "error",
            autoHideDuration: 5000,
          });
        else setDateTo(value);
      }, 500)
    );

  };
  // console.log("savedfil", savedFilters)


  // useEffect(() => {
  //   if(openSavedFilters) {
  //     getFilters(
  //       {
  //       variables: {
  //         model: {
  //           userID: storeUserGuid,
  //           tableName: Mnemonic.Tables.TasksBacklog
  //         },
  //       },
  //     },
  //     (response) => {
  //       // console.log(response?.GetUserTableFilters?.userTableFiltersList);
  //       setSavedFilters(response?.GetUserTableFilters?.userTableFiltersList || [])
  //     })
  //   }
  // }, [openSavedFilters])

  const handleDownload = () => {
    getData(
      {
        variables: {
          model: {
            tasksIDs: selected,
            dateFrom: new Date(dateFrom),
            dateTo: new Date(dateTo),
            format:
              day_month_year.includes(userTimezone) === true
                ? "dd-MM-yyyy"
                : "MM-dd-yyyy",
            includeSubtasks: includeSubtasks,
            myTasks: assignedToMe,
            myTeamsTasks: assignedToMyTeam,
            myProjectsTasks: myProjectTasks,
            highPriority: doing,
            onlySubtasks: onlySubtasks,
            onlyTasks: onlyTasks,
            enableFilters: true,
            filters: defaultFilters,
            tenantID: storeUserTenant,
            userID: storeUserGuid,
            projectID: pID,
          },
        },
      },
      (response) => {
        exportToExcel(
          response?.ProjectExportTimeEntries?.projectTimeEntriesListForExcel
            ?.timeEntries,
          fileName
        );
        setSelected([]);
        setOpenExcelModal(false);
      }
    );
  };

  const content = () => (
    <>
      <Grid item xs={12} sm={6} md={6} lg={6} xl={6} mt={4}>
        <RoundedTextField
          fullWidth
          size="small"
          placeholder={t("task.list.exportToExcel.fileName")}
          value={fileName}
          onChange={(e) => {
            setFileName(e.target.value);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6} xl={6} mt={2}>
        <RoundedDatePicker
          className="date_picker_styles"
          label={t("projectDashboard.label.DateFrom")}
          value={dateFrom}
          disableFuture
          onChange={(newValue) => {
            setDateFrom(newValue);
          }}
          onClose={() => setOpen(true)}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6} xl={6} mt={2}>
        <RoundedDatePicker
          className="date_picker_styles"
          label={t("projectDashboard.label.DateTo")}
          value={dateTo}
          shouldDisableDate={disableDates}
          autoFocus={open}
          open={open}
          onClose={() => setOpen(false)}
          onClick={() => setOpen(!open)}
          onOpen={() => setOpen(!open)}
          onChange={(newValue) => handleValidateDates(newValue)}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6} xl={6} mt={2} mb={4} ml={2}>
        <FormGroup
          sx={{
            ".MuiTypography-root": {
              fontSize: "14px !important",
            },
            "& .MuiSvgIcon-root": { fontSize: 16 },
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => setIncludeSubtasks(e.target.checked)}
              />
            }
            label={t("taskModal.includeSubtasks")}
          />
        </FormGroup>
      </Grid>
    </>
  );

  useEffect(() => {
    const priorityIndex = filterState.findIndex(
      (obj) => obj.property === "priority"
    );
    if (priorityIndex >= 0 && filterState[priorityIndex].value != null) {
      setChipFilters({
        ...chipFilters,
        highPriority: false,
        doing: false,
      });
      setDoing(false);
    }
  }, [filterState]);

  const handleChipClick = (newChipFilters) => {

    let updatedDefaultFilters = { ...defaultFilters };
    let updatedFilterState = [...filterState];

    if (newChipFilters.assignedToMe) {
      updatedDefaultFilters = {
        ...updatedDefaultFilters,
        employeeIDs: [storeUserGuid],
      };

      const employeeIndex = updatedFilterState.findIndex(
        (obj) => obj.property === "employeeIDs"
      );
      if (employeeIndex >= 0) {
        updatedFilterState[employeeIndex].value = [storeUserGuid];
      } else {
        updatedFilterState.push({
          value: [storeUserGuid],
          property: "employeeIDs",
        });
      }

      const teamIndex = updatedFilterState.findIndex(
        (obj) => obj.property === "teamsIDs"
      );
      if (teamIndex >= 0) {
        updatedFilterState[teamIndex].value =
          updatedFilterState[teamIndex]?.value || [];
      } else {
        updatedFilterState.push({ value: null, property: "teamsIDs" });
      }
    } else {
      const employeeIndex = updatedFilterState.findIndex(
        (obj) => obj.property === "employeeIDs"
      );
      if (employeeIndex >= 0) {
        updatedFilterState[employeeIndex].value = updatedFilterState[
          employeeIndex
        ].value?.filter((id) => id !== storeUserGuid);

        if (updatedFilterState[employeeIndex].value?.length === 0) {
          updatedFilterState.splice(employeeIndex, 1);
        }
      }

      delete updatedDefaultFilters.employeeIDs;
    }

    if (newChipFilters.assignedToMyTeam) {
      const filteredTeams = storeUserTeams?.map((x) => x.team);
      const filteredTeamsGuids = filteredTeams.map((t) => t.teamGuid);

      updatedDefaultFilters = {
        ...updatedDefaultFilters,
        teamsIDs: filteredTeamsGuids,
      };

      const teamIndex = updatedFilterState.findIndex(
        (obj) => obj.property === "teamsIDs"
      );
      if (teamIndex >= 0) {
        updatedFilterState[teamIndex].value = filteredTeamsGuids;
      } else {
        updatedFilterState.push({
          value: filteredTeamsGuids,
          property: "teamsIDs",
        });
      }

      const employeeIndex = updatedFilterState.findIndex(
        (obj) => obj.property === "employeeIDs"
      );
      if (employeeIndex >= 0) {
        updatedFilterState[employeeIndex].value =
          updatedFilterState[employeeIndex].value?.map(
            (x) => x?.employeeGuid
          ) || [];
      } else {
        updatedFilterState.push({ value: null, property: "employeeIDs" });
      }
    } else {
      const teamIndex = updatedFilterState.findIndex(
        (obj) => obj.property === "teamsIDs"
      );
      if (teamIndex >= 0) {
        const existingTeamIDs = updatedFilterState[teamIndex].value || [];
        const filteredTeams = storeUserTeams?.map((x) => x.team);
        const filteredTeamsGuids = filteredTeams.map((t) => t.teamGuid);
        updatedFilterState[teamIndex].value = existingTeamIDs.filter(
          (id) => !filteredTeamsGuids.includes(id)
        );

        if (updatedFilterState[teamIndex].value?.length === 0) {
          updatedFilterState.splice(teamIndex, 1);
        }
      }

      delete updatedDefaultFilters.teamsIDs;
    }

    if (newChipFilters.myProjectTasks) {
      updatedDefaultFilters = {
        ...updatedDefaultFilters,
        employeeIDs: null,
        teamsIDs: null,
      };

      const teamIndex = updatedFilterState.findIndex(
        (obj) => obj.property === "teamsIDs"
      );
      if (teamIndex >= 0) {
        updatedFilterState[teamIndex].value = null;
      } else {
        updatedFilterState.push({ value: null, property: "teamsIDs" });
      }

      const employeeIndex = updatedFilterState.findIndex(
        (obj) => obj.property === "employeeIDs"
      );
      if (employeeIndex >= 0) {
        updatedFilterState[employeeIndex].value = null;
      } else {
        updatedFilterState.push({ value: null, property: "employeeIDs" });
      }
    }

    if (newChipFilters.highPriority) {
      updatedDefaultFilters = {
        ...updatedDefaultFilters,
        priority: null,
      };

      const priorityIndex = updatedFilterState.findIndex(
        (obj) => obj.property === "priority"
      );
      if (priorityIndex >= 0) {
        updatedFilterState[priorityIndex].value = null;
      } else {
        updatedFilterState.push({ value: null, property: "priority" });
      }
    }

    setDefaultFilters(updatedDefaultFilters);
    setFilterState(updatedFilterState);
    // setChipFiltersClicked(!chipFiltersClicked);
  };

  const rightChips = [];

  const chips = [
    {
      label: t("ts.showParent"),
      tooltip: t("ts.tooltip.showParent"),
      onClick: () => {
        count.current = count.current + 1;
        // handleChips({
        //   ...chipFilters,
        //   showParents: !chipFilters.showParents,
        // })

        const newShowParents = !chipFilters.showParents;
        const newChipFilters = {

          ...chipFilters,
          showParents: newShowParents,

        };
        setShowParents(!showParents);
        setChipFilters(newChipFilters);

        setPagination({ pageIndex: 0, pageSize: 20 });
      },
      color: chipFilters.showParents ? "primary" : "default",
      variant: chipFilters.showParents ? "filled" : "outlined",
      sx: {
        ".MuiButtonBase-root": {
          border: `1px solid ${colors.chip_border} !important`,
        },
        "&.MuiChip-root": {
          border: `1px solid ${colors.chip_border} !important`,
        },
        "& .MuiChip-label": {
          color: chipFilters.showParents
            ? "white"
            : `${colors.chip_label} !important`,
        },
      },
      visible: pID ? true : false,
    },

    {
      label: t("ts.AssignedToMe"),
      tooltip: t("ts.assignedtometooltip"),
      visible: true,
      onClick: () => {
        count.current = count.current + 1;
        const newAssignedToMe = !chipFilters.assignedToMe;
        const newChipFilters = {
          ...chipFilters,
          assignedToMe: newAssignedToMe,
          myProjectTasks:
            chipFilters.assignedToMyTeam || newAssignedToMe ? false : true,
        };
        setChipFilters(newChipFilters);
        setAssignedToMe(newAssignedToMe);
        if (!newAssignedToMe && !chipFilters.assignedToMyTeam) {
          setMyProjectTasks(true);
        }
        handleChipClick(newChipFilters);
        setPagination({ pageIndex: 0, pageSize: 20 });
      },
      color: chipFilters.assignedToMe ? "primary" : "default",
      variant: chipFilters.assignedToMe ? "filled" : "outlined",
      sx: {
        ".MuiButtonBase-root": {
          border: `1px solid ${colors.chip_border} !important`,
        },
        "&.MuiChip-root": {
          border: `1px solid ${colors.chip_border} !important`,
        },
        "& .MuiChip-label": {
          color: chipFilters.assignedToMe
            ? "white"
            : `${colors.chip_label} !important`,
        },
      },
    },

    {
      label: t("ts.AssignedToMyTeam"),
      tooltip: t("ts.assignedtomeyteamtooltip"),
      visible: true,
      onClick: () => {
        count.current = count.current + 1;
        const newAssignedToMyTeam = !chipFilters.assignedToMyTeam;
        const newChipFilters = {
          ...chipFilters,
          assignedToMyTeam: newAssignedToMyTeam,
          myProjectTasks:
            chipFilters.assignedToMe || newAssignedToMyTeam ? false : true,
        };
        setChipFilters(newChipFilters);
        setAssignedToMyTeam(newAssignedToMyTeam);
        if (!newAssignedToMyTeam && !chipFilters.assignedToMe) {
          setMyProjectTasks(true);
        }
        handleChipClick(newChipFilters);
        setPagination({ pageIndex: 0, pageSize: 20 });
      },
      color: chipFilters.assignedToMyTeam ? "primary" : "default",
      variant: chipFilters.assignedToMyTeam ? "filled" : "outlined",
      sx: {
        ".MuiButtonBase-root": {
          border: `1px solid ${colors.chip_border} !important`,
        },
        "&.MuiChip-root": {
          border: `1px solid ${colors.chip_border} !important`,
        },
        "& .MuiChip-label": {
          color: chipFilters.assignedToMyTeam
            ? "white"
            : `${colors.chip_label} !important`,
        },
      },
    },

    {
      label: t("ts.OnlyTasks"),
      tooltip: t("ts.taskstooltip"),
      visible: true,
      onClick: () => {
        count.current = count.current + 1;
        setChipFilters((prevFilters) => {
          const newOnlyTasks = !prevFilters.onlyTasks;
          const newOnlySubtasks = prevFilters.onlySubtasks;

          if (!newOnlyTasks && !newOnlySubtasks) {
            return {
              ...prevFilters,
              onlyTasks: false,
              onlySubtasks: true,
            };
          }

          setOnlyTasks(newOnlyTasks);
          return {
            ...prevFilters,
            onlyTasks: newOnlyTasks,
          };
        });
        setPagination({ pageIndex: 0, pageSize: 20 });
      },
      color: chipFilters.onlyTasks ? "primary" : "default",
      variant: chipFilters.onlyTasks ? "filled" : "outlined",
      sx: {
        ".MuiButtonBase-root": {
          border: `1px solid ${colors.chip_border} !important`,
        },
        "&.MuiChip-root": {
          border: `1px solid ${colors.chip_border} !important`,
        },
        "& .MuiChip-label": {
          color: chipFilters.onlyTasks
            ? "white"
            : `${colors.chip_label} !important`,
        },
      },
    },
    {
      label: t("ts.OnlySubtasks"),
      tooltip: t("ts.subtaskstooltip"),
      visible: true,
      onClick: () => {
        count.current = count.current + 1;
        setChipFilters((prevFilters) => {
          const newOnlySubtasks = !prevFilters.onlySubtasks;
          const newOnlyTasks = prevFilters.onlyTasks;

          if (!newOnlySubtasks && !newOnlyTasks) {
            return {
              ...prevFilters,
              onlySubtasks: false,
              onlyTasks: true, // Turn on onlyTasks if both would be off
            };
          }

          setOnlySubtasks(newOnlySubtasks);
          return {
            ...prevFilters,
            onlySubtasks: newOnlySubtasks,
          };
        });
        setPagination({ pageIndex: 0, pageSize: 20 });
      },
      color: chipFilters.onlySubtasks ? "primary" : "default",
      variant: chipFilters.onlySubtasks ? "filled" : "outlined",
      sx: {
        ".MuiButtonBase-root": {
          border: `1px solid ${colors.chip_border} !important`,
        },
        "&.MuiChip-root": {
          border: `1px solid ${colors.chip_border} !important`,
        },
        "& .MuiChip-label": {
          color: chipFilters.onlySubtasks
            ? "white"
            : `${colors.chip_label} !important`,
        },
      },
    },

    {
      label: t("ts.myprojectTasks"),
      tooltip: t("ts.myprojectstooltip"),
      visible: true,
      onClick: () => {
        count.current = count.current + 1;
        const newMyProjectTasks = !chipFilters.myProjectTasks;
        const newChipFilters = {
          ...chipFilters,
          myProjectTasks: newMyProjectTasks,
          assignedToMe: newMyProjectTasks ? false : chipFilters.assignedToMe,
          assignedToMyTeam: newMyProjectTasks
            ? false
            : chipFilters.assignedToMyTeam,
        };
        setChipFilters(newChipFilters);
        setMyProjectTasks(newMyProjectTasks);
        if (
          !newMyProjectTasks &&
          !chipFilters.assignedToMe &&
          !chipFilters.assignedToMyTeam && !isAdmin
        ) {
          setAssignedToMe(true);
          newChipFilters.assignedToMe = true;
        }
        handleChipClick(newChipFilters);
        setPagination({ pageIndex: 0, pageSize: 20 });
      },
      color: chipFilters.myProjectTasks ? "primary" : "default",
      variant: chipFilters.myProjectTasks ? "filled" : "outlined",
      sx: {
        ".MuiButtonBase-root": {
          border: `1px solid ${colors.chip_border} !important`,
        },
        "&.MuiChip-root": {
          border: `1px solid ${colors.chip_border} !important`,
        },
        "& .MuiChip-label": {
          color: chipFilters.myProjectTasks
            ? "white"
            : `${colors.chip_label} !important`,
        },
      },
    },

    {
      tooltip: t("ts.prioritytooltip"),
      icon: getOptionPriorityIcon("High", { marginRight: "-17px" }),
      visible: true,

      onClick: () => {
        count.current = count.current + 1;
        const newChipFilters = {
          ...chipFilters,
          highPriority: true,
        };
        // handleChips({
        //   ...chipFilters,
        //   doing: !chipFilters.doing,
        // })

        handleChipClick(newChipFilters);
        setDoing(!doing);
        setChipFilters((prevFilters) => ({
          ...prevFilters,
          doing: !prevFilters.doing,
        }));
        setPagination({ pageIndex: 0, pageSize: 20 });
        // refetchData();
      },
      color: doing ? "primary" : "default",
      variant: doing ? "filled" : "outlined",
      sx: {
        ".MuiButtonBase-root": {
          border: `1px solid ${colors.chip_border} !important`,
        },
        "&.MuiChip-root": {
          border: `1px solid ${colors.chip_border} !important`,
        },
        "& .MuiChip-label": {
          color: doing ? "white" : `${colors.chip_label} !important`,
        },
      },
    },
    {
      label: openSavedFilters
        ? t("ts.hideSavedFilters")
        : t("ts.showSavedFilters"),
      onClick: () => {
        setOpenSavedFilters((oldValue) => !oldValue);
      },
      variant: openSavedFilters ? "filled" : "outlined",
      sx: {
        ".MuiButtonBase-root": {
          border: `1px solid ${colors.chip_border} !important`,
        },
        "&.MuiChip-root": {
          border: `1px solid ${colors.chip_border} !important`,
        },
        "& .MuiChip-label": {
          color: openSavedFilters
            ? `${colors.icon_button_grey} !important`
            : `${colors.chip_label} !important`,
        },
      },
      visible: true,
    },
  ];

  const handleRefetchTree = () => {
    showParents
      ? getTreeData(
        chipFilters,
        sorting,
        rowCount,
        search,
        defaultFilters,
        pagination
      )
      : fetchTableData(
        page || 0,
        rowsPerPage || 20,
        order,
        orderBy,
        search,
        defaultFilters || null,
        chipFilters
      );
  };

  const getTotalFilters = () => {
    return {
      quickFilters: chipFilters,
      defaultFilters: defaultFilters,
    };
  };

  const check_filters = (f) => {
    if (f.hasOwnProperty("projectID")) {
      setFetchProjects(true);
      setSelectedProject(
        projectsList?.filter((x) => x.projectGuid === f.projectID)[0]
      );
    }
    if (f.hasOwnProperty("parentIDs")) setFetchTasks(true);
    if (f.hasOwnProperty("areaIDs")) setFetchAreas(true);
  };



  const handleApplyFilter = (guid, filterObject) => {


    setPage(0);
    if (!pID) {
      localStorage.setItem("task_filter", JSON.stringify(filterObject));
      localStorage.setItem("task_filter_guid", JSON.stringify(guid));
    }
    else {
      localStorage.setItem("project_task_filter", JSON.stringify(filterObject));
      localStorage.setItem("project_task_filter_guid", JSON.stringify(guid));
    }
    const parsedObject = JSON.parse(filterObject);
    const quickFilters = parsedObject?.quickFilters;
    check_filters(parsedObject?.filters);

    setAppliedFilters(parsedObject)
    showParents
      ? getTreeData(
        {
          showParents: quickFilters?.showParents,
          assignedToMe: quickFilters?.myTasks,
          assignedToMyTeam: quickFilters?.myTeamsTasks,
          onlySubtasks: quickFilters?.onlySubtasks,
          onlyTasks: quickFilters?.onlyTasks,
          myProjectTasks: quickFilters?.myProjectTasks,
          doing: quickFilters?.doing,
        },

        chipFilters,
        sorting,
        rowCount,
        search,
        parsedObject?.filters,
        pagination

      )
      : fetchTableData(
        page || 0,
        rowsPerPage || 20,
        order,
        orderBy,
        search,
        parsedObject?.filters,
        {
          showParents: quickFilters?.showParents,
          assignedToMe: quickFilters?.myTasks,
          assignedToMyTeam: quickFilters?.myTeamsTasks,
          onlySubtasks: quickFilters?.onlySubtasks,
          onlyTasks: quickFilters?.onlyTasks,
          myProjectTasks: quickFilters?.myProjectTasks,
          doing: quickFilters?.doing,
        }
      );
    setDefaultFilters(parsedObject?.filters);
    setSelectedFilterGuid(guid);
    setChipFilters({
      showParents: quickFilters?.showParents,
      assignedToMe: quickFilters?.myTasks,
      assignedToMyTeam: quickFilters?.myTeamsTasks,
      onlySubtasks: quickFilters?.onlySubtasks,
      onlyTasks: quickFilters?.onlyTasks,
      myProjectTasks: quickFilters?.myProjectTasks,
      doing: quickFilters?.doing,
    });
    setDoing(quickFilters?.highPriority);
  };

  const columnsConfig = useColumnsConfig(
    columns,
    t,
    rowGuid,
    handleRefetchTree,
    chipFilters,
    defaultFilters,
    sorting,
    search,
    data,
    setData,
    getTotalFilters,
    taskStateList
  );

  useEffect(() => {
    if (pagination) {
      setPage(pagination?.pageIndex);
      setRowsPerPage(pagination?.pageSize);
    }
  }, [pagination]);

  const table = useMaterialReactTable({
    columns: columnsConfig,
    data: loading ? [] : data,
    enableSelectAll: false,
    enableColumnPinning: true,
    enableExpandAll: true,
    enableExpanding: showParents ? true : false,
    columnResizeMode: "onChange",
    enableStickyHeader: true,
    enableColumnResizing: true,
    enableDensityToggle: false,
    enableSubRowSelection: false,
    enableFullScreenToggle: false,
    enableFilters: false,
    enableHiding: false,
    enableGlobalFilter: false,
    enableColumnActions: false,
    enableRowSelection: true,
    enableBatchRowSelection: false,
    enablePagination: !showParents,
    // manualFiltering: false,
    enableTopToolbar: false,
    enableSorting: true,
    getIsSomeRowsExpanded: true,
    onColumnOrderChange: setColumnOrder,
    initialState: {
      density: "compact",
      columnPinning: {
        left: [
          "mrt-row-expand",
          "mrt-row-drag",
          "mrt-row-select",
          "ID",
          "Title",
        ],
      },
    },
    manualFiltering: true,
    manualPagination: true,
    paginateExpandedRows: true,
    manualSorting: true,
    muiToolbarAlertBannerProps: isError
      ? {
        color: "error",
        children: "Error loading data",
      }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: setSorting,
    rowCount,
    onPaginationChange: setPagination,
    state: {
      columnFilters,
      columnOrder,
      isLoading: loading || tasksListLoading,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      pagination,
      sorting,
      rowSelection,
    },

    //Edit Row
    createDisplayMode: "row",
    editDisplayMode: "table",
    enableEditing: columnsConfig.some((column) => column?.enableEditing),

    displayColumnDefOptions: {
      "mrt-row-drag": {
        enableResizing: false,
        size: 20,
        header: () => null,
      },
      "mrt-row-expand": {
        enableResizing: true,
        size: showParents ? 70 : 50,
      },
      "mrt-row-select": {
        size: 20,
        header: ({ column }) => null,
      },
    },
    getRowId: (originalRow) => originalRow?.task?.guid,
    onRowSelectionChange: setRowSelection,
    getSubRows: (row) => {
      return row?.subtasks;
    },
    enableRowOrdering: pID ? true : false,
    muiRowDragHandleProps: ({ table }) => ({
      onDragEnd: () => {
        const { draggingRow, hoveredRow } = table.getState();

        if (hoveredRow && draggingRow) {
          const {
            id: draggingId,
            original: {
              task: {
                parentTaskGuid: draggingParentTaskGuid,
                ticketGuid: draggingTicketGuid,
                projectGuid: draggingProjectGuid,
              },
            },
          } = draggingRow;

          const {
            id: hoveredId,
            original: {
              task: {
                parentTaskGuid: hoveredParentTaskGuid,
                ticketGuid: hoveredTicketGuid,
                projectGuid: hoveredProjectGuid,
                title: title,
              },
            },
          } = hoveredRow;

          const movedRow = {
            id: draggingId,
            parentGuid: draggingParentTaskGuid,
            ticketGuid: draggingTicketGuid,
            projectGuid: draggingProjectGuid,
          };
          const targetRow = {
            id: hoveredId,
            parentGuid: hoveredParentTaskGuid,
            ticketGuid: hoveredTicketGuid,
            projectGuid: hoveredProjectGuid,
            title: title,
          };

          setMovedRow(movedRow);
          setTargetRow(targetRow);
          setPendingMove({
            draggingRowIndex: draggingRow.index,
            hoveredRowIndex: hoveredRow.index,
          });

          setOpenMovedRowDialog(true); // Open the confirmation dialog
        }
      },
    }),
    muiTableHeadCellProps: {
      sx: {
        ...tableStyles.header,
        "& .MuiDivider-root": {
          borderWidth: "0.00001px",
          height: "48px",
          borderColor: "rgba(0, 0, 0, 0.06) !important",
          marginBottom: "7px",
        },
      },
    },

    muiTableFooterCellProps: {
      sx: tableStyles.footer,
    },
    muiTableBodyProps: {
      sx: tableStyles.body,
    },
    muiTableContainerProps: {
      sx: {
        maxHeight: fromTicket ? "calc(100vh - 355px)"
          : !fromProjectTasks
            ? showQuickFilters == true
              ? "calc(100vh - 275px)"
              : "calc(100vh - 213px)"
            : showQuickFilters == true
              ? "calc(100vh - 352px)"
              : "calc(100vh - 293px)",
      },
    },
    muiCircularProgressProps: {
      color: "primary",
      thickness: 4,
      size: 35,
    },
    muiSkeletonProps: {
      animation: "pulse",
      height: 28,
    },

    muiTableBodyCellProps: ({ column }) => {
      const cellAligned = column?.columnDef?.align;
      const baseStyles =
        cellAligned === "center"
          ? { display: "flex", justifyContent: "center" }
          : {};

      // Check if the column has enableEditing set to true
      const isEditableColumn = column.columnDef.enableEditing;

      return {
        sx: {
          ...baseStyles,
          "&:hover": isEditableColumn
            ? {
              border: "1px solid #dcdcdc",
            }
            : {},
        },
      };
    },
  });

  const tablePagination = {
    rows,
    columns,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    selected,
    defaultFilters,
    setDefaultFilters,
    setSelected,
    search,
    setSearch,
    fetchTableData: showParents ? null : fetchTableData,
    setPage,
    setRowsPerPage,
    numberRecords: numberOfRecords,
    handleRefresh,
    page,
    rowsPerPage,
    getTreeData: showParents ? getTreeData : null,
    loading: loading || columnsWait || tasksListLoading,
    searchText: fromTenant
      ? "task.list.search.label.fromTenant"
      : "task.list.search.label",
    refetch,
    tableMnemonic: Mnemonic.Tables.TasksBacklog,
    filtersObject,
    generateRoute: (row) =>
      fromTenant
        ? !fromTicket
          ? tenant_project_direct_tasks_form
            .replace(
              "/:tName/:tID",
              `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
            )
            .replace(
              "/:pName/:pID",
              `/${encodeURIComponent(
                row?.ticket?.project?.title
              )}/${encodeURIComponent(row?.ticket?.project?.projectGuid)}`
            )
            .replace(
              "/:tskName/:tskID",
              `/${encodeURIComponent(row?.title)}/${encodeURIComponent(
                row?.taskGuid
              )}`
            )
          : tenant_project_tasks_form
            .replace(
              "/:tName/:tID",
              `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
            )
            .replace(
              "/:pName/:pID",
              `/${encodeURIComponent(
                row?.ticket?.project?.title
              )}/${encodeURIComponent(row?.ticket?.project?.projectGuid)}`
            )
            .replace(
              "/:tkName/:tkID",
              `/${encodeURIComponent(tkName)}/${encodeURIComponent(tkID)}`
            )
            .replace(
              "/:tskName/:tskID",
              `/${encodeURIComponent(row?.title)}/${encodeURIComponent(
                row?.taskGuid
              )}`
            )
        : !fromTicket
          ? outSide
            ? project_backlog_tasks.replace(
              "/:tskName/:tskID",
              `/${encodeURIComponent(row?.title)}/${encodeURIComponent(
                row?.taskGuid
              )}`
            )
            : my_project_direct_tasks_form
              .replace(
                "/:pName/:pID",
                `/${encodeURIComponent(
                  row?.ticket?.project?.title
                )}/${encodeURIComponent(row?.ticket?.project?.projectGuid)}`
              )
              .replace(
                "/:tskName/:tskID",
                `/${encodeURIComponent(row?.title)}/${encodeURIComponent(
                  row?.taskGuid
                )}`
              )
          : my_project_tasks_form
            .replace(
              "/:pName/:pID",
              `/${encodeURIComponent(
                row?.ticket?.project?.title
              )}/${encodeURIComponent(row?.ticket?.project?.projectGuid)}`
            )
            .replace(
              "/:tkName/:tkID",
              `/${encodeURIComponent(tkName)}/${encodeURIComponent(tkID)}`
            )
            .replace(
              "/:tskName/:tskID",
              `/${encodeURIComponent(row?.title)}/${encodeURIComponent(
                row?.taskGuid
              )}`
            ),
    disableAddBtn: true,
    deleteText: "task.list.delete.label",
    handleDelete: handleDelete,
    deleteLoading,
    readOnly: isEmployee === Mnemonic.ResourceTypes.External,
    handleOpenModal,
    handleChangeState: (v) => setFilterState(v),
    customButtons,
    setPagination,
    heightClassName:
      pID !== null && pID !== undefined && pID !== ""
        ? showQuickFilters === true
          ? "table-project-filter-height"
          : "table-project-height"
        : showQuickFilters === true
          ? "table-filter-height"
          : "table-height",
  };

  useEffect(() => {
    const hasSelection = Object.keys(rowSelection).length > 0;
    setDeleteIcon(hasSelection);
    setRowGuid(table.getState().rowSelection); // Update rowGuid if needed
  }, [rowSelection]);

  const customModalButtons = () => (
    <>
      <CustomButton onClick={handleDownload} label={t("dialog.download")} />
      {dataLoading && (
        <Box
          sx={{
            backgroundColor: "#00000025",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        >
          <CircularProgress
            size={24}
            sx={{
              color: "#337ab7",
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
              zIndex: 12,
            }}
          />
        </Box>
      )}
    </>
  );

  const handleMovedRowClose = () => {
    setOpenMovedRowDialog(false);
    setPendingMove(null); // Reset pending move state on close
  };

  const handleMovedRowConfirm = () => {
    const { draggingRowIndex, hoveredRowIndex } = pendingMove;

    // Rearranging the data
    data.splice(hoveredRowIndex, 0, data.splice(draggingRowIndex, 1)[0]);
    setData([...data]);

    handleSubmit(movedRow, targetRow); // Perform the move operation

    handleMovedRowClose(); // Close the confirmation dialog
  };

  const customModalMovedRowButtons = () => (
    <>
      <CustomButton onClick={handleMovedRowConfirm} label={t("dialog.yes")} />
      <div>
        <CustomButton
          onClick={handleMovedRowClose}
          label={t("dialog.cancel")}
        />
      </div>
    </>
  );

  const handleClose = () => {
    setFileName("");
    setIncludeSubtasks(false);
    setOpenExcelModal(false);
  };

  const handleCloseDeleteFilterModal = () => {
    setOpenDeleteFilterModal(false);
  };

  const customDeleteFilterModalButtons = () => (
    <>
      <CustomButton
        onClick={() => {
          handleRemoveUserTableFilters();
          handleCloseDeleteFilterModal();
        }}
        label={t("dialog.yes")}
      />
      <div>
        <CustomButton
          onClick={handleCloseDeleteFilterModal}
          label={t("dialog.cancel")}
        />
      </div>
    </>
  );

  return (
    <div
      style={
        fromEmployeeAvailability === true
          ? {
            width: "inherit",
            position: "absolute",
            top: "40px",
            bottom: "50px",
            right: "3%",
            left: "3%",
          }
          : {}
      }
    >
      <ConfirmationModal
        contentText={`${t("tasksBacklog.movedRow")}  "${targetRow?.title}" ?`}
        title={t("dialog.warning")}
        icon={"warning"}
        openDialog={openMovedRowDialog}
        handleClose={handleMovedRowClose}
        customButtons={customModalMovedRowButtons}
        t={t}
      />
      <TaskTabsModal
        showModal={openModal}
        setShowModal={(v) => setOpenModal(v)}
        loading={loading || statusLoading}
        projectID={projectID}
        projectName={projectName}
        taskID={taskID}
        handleChangeTask={(v) => setTaskID(v)}
        handleChangeProject={(v) => setProjectID(v)}
        taskName={taskName}
        refetch={() => {
          if (showParents)
            getTreeData(
              chipFilters,
              sorting,
              rowCount,
              search,
              defaultFilters,
              pagination
            );
          // : fetchTableData(
          //     page || 0,
          //     rowsPerPage || 20,
          //     order,
          //     orderBy,
          //     search,
          //     defaultFilters || null,
          //     chipFilters
          //   )
        }}
        fromProject={true}
        open={JSON.parse(sessionStorage.getItem("unsavedChanges"))}
        modifyFormFlag={() =>
          sessionStorage.setItem("unsavedChanges", JSON.stringify(false))
        }
        type={type}
        setType={(v) => setType(v)}
        parent={parent}
        taskStatusList={taskStateList}
        data={data}
        updateData={(v) => setData(v)}
        totalFilters={chipFilters}
        // addNewSubtask={addNewSubtask.current}
        editMode={!addNewSubtask.current}
      />
      <ConfirmationModal
        title={t("task.list.exportToExcel.dialog.title")}
        icon={ExcelIcon()}
        openDialog={openExcelModal}
        handleClose={handleClose}
        customButtons={customModalButtons}
        closeButton={true}
        content={content}
        t={t}
      />
      <ConfirmationModal
        contentText={t("task.list.deleteFilter.dialog.text")}
        title={t("dialog.warning")}
        icon={"warning"}
        openDialog={openDeleteFilterModal}
        handleClose={handleClose}
        customButtons={customDeleteFilterModalButtons}
        t={t}
      />
      {getColumnsLoading ? (
        <ContainerCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <CircularProgress />
        </ContainerCard>
      ) : (
        <Grid container>
          <Grid
            item
            xs={openSavedFilters ? 9.5 : 12}
            sx={openSavedFilters ? { paddingRight: "10px !important" } : {}}
          >
            <DataTable
              {...tablePagination}
              treeTable={true}
              deleteIcon={deleteIcon}
              identifier={"task.taskGuid"}
              hasCheckbox
              quickFilters
              chips={chips}
              rightChips={rightChips}
              chipFilters={chipFilters}
              handleDefaultFilters={(f) => setFilters(f)}
              handleShowQuickFilters={(t) => setShowQuickFilters(t)}
              savedFilterFlag={true}
              showUpdateFilterButton={
                selectedFilterGuid !== null &&
                selectedFilterGuid !== undefined &&
                selectedFilterGuid !== ""
              }
              handleUpdateFilter={handleSaveUserTableFilters}
              handleSaveFilter={handleSaveUserTableFilters}
              handleClearFilter={() => setSelectedFilterGuid(null)}
              defaultChipFilters={{
                showParents: pID ? true : false,
                assignedToMe: true,
                assignedToMyTeam: true,
                onlySubtasks: true,
                onlyTasks: true,
                myProjectTasks: false,
                doing: false,
              }}
              handleResetChipFilter={() =>
                setChipFilters({
                  showParents: pID ? true : false,
                  assignedToMe: true,
                  assignedToMyTeam: true,
                  onlySubtasks: true,
                  onlyTasks: true,
                  myProjectTasks: false,
                  doing: false,
                })
              }
              ticketHeight={fromTicket ? true : false}
            />

            <MaterialReactTable table={table} />
          </Grid>
          {openSavedFilters && (
            <Grid item xs={2.5} className="savedFilters">
              <SavedFilters
                filters={savedFilters}
                loading={filterLoading}
                handleApplyFilter={handleApplyFilter}
                selectedFilterGuid={selectedFilterGuid}
                handleSaveUserTableFilters={handleSaveUserTableFilters}
                handleRemoveUserTableFilters={(guid) => {
                  setFilterGuidToBeRemoved(guid);
                  setOpenDeleteFilterModal(true);
                }}
              />
            </Grid>
          )}
        </Grid>
      )}
    </div>
  );
};

export default TasksList;
